import { RclCol, RclText } from '@risepeopleinc/rcl-react';
import { useState, useEffect, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { DepartmentMapping } from '../OverrideMapping/DepartmentMapping';
import { EmployeeMapping } from '../OverrideMapping/EmployeeMapping';
import { JournalEntryFormHeader } from '../JournalEntryFormHeader/JournalEntryFormHeader';
import { OverrideMappingHeader } from '../OverrideMappingHeader/OverrideMappingHeader';
import SelectDebitCredit from '../SelectDebitCredit/SelectDebitCredit'
import { useJEFormContext } from '../../../../hooks/useJEFormContext';
import "./JournalEntryForm.scss"
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { selectCurrentJournalEntry } from '../../../../reducers/journalEntrySlice';
import { IJournalEntry, OVERRIDE_TABS } from '../../../../interfaces/IJournalEntry';
import { isMappingComplete } from '../../../../helpers';
import { selectDashboardHeaderIsOnTop } from '../../../../reducers/headerSlice';
import KBArticleLink from '../KBArticleLink/KBArticleLink';

const JournalEntryForm = () => {
  const dashboardHeaderIsOnTop = useAppSelector(selectDashboardHeaderIsOnTop);
  const [selectedTab, setSelectedTab] = useState<string | undefined>(undefined)
  const [hasEmployeeOverride, setHasEmployeeOverride] = useState<boolean>(false)
  const [hasDepartmentOverride, setHasDepartmentOverride] = useState<boolean>(false)
  const { state } = useJEFormContext()
  const selectedEntry = useAppSelector(selectCurrentJournalEntry)
  const previousSelectedEntryRef = useRef<IJournalEntry>();

  useEffect(() => {
    if (!isMappingComplete(state['main'])) setSelectedTab(undefined)
    if (previousSelectedEntryRef.current?.id !== selectedEntry?.id) {
      previousSelectedEntryRef.current = selectedEntry;
      setSelectedTab(undefined)
    }
  }, [selectedEntry, state])

  useEffect(() => {
    Object.values(OVERRIDE_TABS).forEach(element => {
      const checkIfAlreadyHasOverride = Object.entries(state)
        .filter(([k]) => k.includes(element))
        .some(([_k, v]) => v.credit && v.debit)
      if (checkIfAlreadyHasOverride) {
        if (element === OVERRIDE_TABS.department) {
          setHasDepartmentOverride(true)
          if (!selectedTab) setSelectedTab(OVERRIDE_TABS.department)
        }
        if (element === OVERRIDE_TABS.employee) {
          setHasEmployeeOverride(true)
          if (!selectedTab) setSelectedTab(OVERRIDE_TABS.employee)
        }
      }
      else {
        if (element === OVERRIDE_TABS.department) setHasDepartmentOverride(false)
        if (element === OVERRIDE_TABS.employee) setHasEmployeeOverride(false)
      }
    });
  }, [state, selectedTab])

  return (
    <RclCol flex={"auto"} span={12} style={{ float: 'right', position: 'sticky', top: '60px', background: 'white', zIndex: 2, width: '50%' }}>
      <div className={`select-debit-credit-wrapper ${dashboardHeaderIsOnTop ? 'update-max-height' : ""}`}>
        <div className='select-debit-credit-top-wrapper'>
          <JournalEntryFormHeader />
          <SelectDebitCredit keyName='main' />
          <div className="need-specific-mappings-wrapper">
            <RclText className="need-specific-mappings-text">
              Need specific mappings? Override the default mapping by department or employee. <b>Employee overrides will take priority over departments.</b>
              <KBArticleLink link='https://support.risepeople.com/portal/en/kb/articles/journal-entries-sl#How_does_the_system_prioritize_mappings' />
              </RclText>
          </div>
        </div>
        <div className='select-debit-credit-middle-wrapper'>
          <OverrideMappingHeader selectedTab={selectedTab} setSelectedTab={setSelectedTab} hasEmployeeOverride={hasEmployeeOverride} hasDepartmentOverride={hasDepartmentOverride} />
        </div>
        {selectedTab
          ? <div className='select-debit-credit-bottom-wrapper'>
            {<DepartmentMapping selectedTab={selectedTab} />}
            {<EmployeeMapping selectedTab={selectedTab} />}
          </div>
          : null}
      </div>
    </RclCol>
  )
}

export const JournalEntryFormSkeleton = () => (
  <RclCol flex={"auto"} span={12} style={{ float: 'right', position: 'sticky', top: '48px', background: 'white', zIndex: 2, width: '50%' }}>
    <div className='select-debit-credit-wrapper' style={{ padding: "24px" }}>
      <Skeleton height={40} width='50%' className='mb-8' />
      <Skeleton height={20} width='60%' className='mb-8' />
      <Skeleton height={40} />
    </div>
  </RclCol>
)

export default JournalEntryForm
