import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk'
import userReducer from './reducers/userSlice';
import orgReducer from './reducers/orgSlice';
import journalEntryReducer from './reducers/journalEntrySlice';
import globalModalsReducer from './reducers/globalModalsSlice';
import headerHeducer from './reducers/headerSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    org: orgReducer,
    journalEntry: journalEntryReducer,
    globalModals: globalModalsReducer,
    header: headerHeducer
  },
  middleware: [thunkMiddleware]
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
