import {
    RclButton,
    RclModal,
    RclTitle,
    RclText,
    RclSpace,
    RclInput,
  } from "@risepeopleinc/rcl-react"
  import { useEffect, useMemo, useState } from "react"
  import './DisconnectModal.scss'
  import xeroDisconnect from "../../../../assets/images/xero-disconnected.svg"
import { useAppSelector } from "../../../../hooks/reduxHooks"
import { selectCurrentIntegrationData, selectCurrentOrg } from "../../../../reducers/orgSlice"
  
  
  const DisconnectModal = ({disconnectIntegration, isDisconnecting} : {disconnectIntegration: any, isDisconnecting: boolean}) => {
    const [open, setOpen] = useState(false);
    const currentIntegration = useAppSelector(selectCurrentIntegrationData);
    const currentOrg = useAppSelector(selectCurrentOrg);

    const [inputValue, setInputValue] = useState("");

    const disconnectEnabled = useMemo(() => inputValue === `Disconnect ${currentOrg?.name}`, [inputValue, currentOrg?.name])

    const onModalClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if (!open) {
            setInputValue("")
        }
    }, [open])
    
    return (
      <>
        <RclButton onClick={() => setOpen(true)}>
            Disconnect
        </RclButton>
        <RclModal 
            wrapClassName="xero-disconnect-modal-wrapper"
            visible={open}
            onCancel={isDisconnecting ? () => {} : onModalClose}
            footer={[
                <div className="xero-disconnect-modal-button-wrapper">
                    <RclButton disabled={isDisconnecting} key="back" onClick={onModalClose}>
                        Cancel
                    </RclButton>
                    <RclButton 
                        loading={isDisconnecting}
                        key="disconnect-xero" 
                        type="default" 
                        danger 
                        onClick={disconnectIntegration}
                        disabled={!disconnectEnabled}
                    >
                        Disconnect
                    </RclButton>
                </div>
            ]}>
            <RclSpace direction='vertical' className="xero-disconnect-modal-body-wrapper">
                <img src={xeroDisconnect} alt="xero-disconnect"></img>
                <RclTitle className="xero-disconnect-title">Disconnect {currentOrg?.name} from {currentIntegration?.name}?</RclTitle>
                <RclText>Doing so will stop you from sending journal entries.</RclText>
            </RclSpace>
            <RclSpace direction='vertical' className="xero-disconnect-input-confirm-wrapper">
                <RclText>To proceed, enter "Disconnect {currentOrg?.name}" below</RclText>
                <RclInput disabled={isDisconnecting} value={inputValue} onChange={e => setInputValue(e.target.value)} placeholder="Enter text" width="100%" />
            </RclSpace>
    
        </RclModal>
      </>
    )
  }
  
  export default DisconnectModal
  