import { RclCol, RclCollapse, RclCollapsePanel } from "@risepeopleinc/rcl-react"
import deepEqual from "deep-equal"
import { useState, useEffect, useMemo, useRef } from "react"
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks"
import useErrorHandling from "../../../../hooks/useErrorHandling"
import useJeApi from "../../../../hooks/useJeApi"
import { IJournalEntry } from "../../../../interfaces/IJournalEntry"
import {
  selectJESort,
  selectJournalEntriesFilteredOutItemTypes,
  selectJournalEntriesLoading,
  setInitialLoad,
  setJournalEntriesLoading,
  setCurrentJournalEntry,
} from "../../../../reducers/journalEntrySlice"
import { selectCurrentOrg } from "../../../../reducers/orgSlice"
import SingleJournalEntry, { SingleJournalEntrySkeleton } from "../SingleJournalEntry/SingleJournalEntry"

const JournalEntriesList = () => {
  const { get } = useJeApi()
  const dispatch = useAppDispatch()
  const currentOrg = useAppSelector(selectCurrentOrg)
  const journalEntries = useAppSelector(selectJournalEntriesFilteredOutItemTypes())
  const journalEntriesLoading = useAppSelector(selectJournalEntriesLoading)
  const JESort = useAppSelector(selectJESort)
  const firstItemRef = useRef<IJournalEntry | null>(null)
  const [activeKey, setActiveKey] = useState<string | string[]>()
  const { handleXeroError } = useErrorHandling()

  const itemTypes: any = useMemo(() => {
    const itemTypesList: any = []
    journalEntries.forEach((je) => {
      itemTypesList.push(je.item_type)
    })
    const uniqueList = new Set(itemTypesList)
    return Array.from(uniqueList)
  }, [journalEntries])

  const defaultOpen = useMemo(() => {
    if (JESort && JESort.field === 'item_type' && itemTypes.length) {
      return itemTypes[0]
    }
  }, [JESort, itemTypes])

  useEffect(() => setActiveKey(defaultOpen), [defaultOpen])

  useEffect(() => {
    if (!currentOrg?.id) return
    (async () => {
      try {
        dispatch(setJournalEntriesLoading(true))
        const [JEItems, fetchMappings, fetchAccounts, departments, employees] = await Promise.all([
          get<any>(`/journal-entry-items?organization_id=${currentOrg.id}`),
          get<any>(`/mappings?organization_id=${currentOrg.id}`),
          get<any>(`/general-ledger-accounts?organization_id=${currentOrg.id}`),
          get<any>(`/departments?organization_id=${currentOrg.id}`),
          get<any>(`/employees?organization_id=${currentOrg.id}`)
        ])

        if (!JEItems?.length) return
        dispatch(setInitialLoad({
          journalEntries: JEItems,
          mappings: fetchMappings,
          generalLedgerAccounts: fetchAccounts,
          departments,
          employees
        }))
        dispatch(setJournalEntriesLoading(false))
      } catch (error) {
        handleXeroError(error)
      }
    })()
  }, [currentOrg?.id, get, dispatch, handleXeroError])

  useEffect(() => {
    if (!deepEqual(firstItemRef.current, journalEntries[0])) {
      firstItemRef.current = journalEntries[0]
      dispatch(setCurrentJournalEntry(journalEntries[0]))
    }
  }, [journalEntries, dispatch])

  return (
    <RclCol className="entries-container" style={{ float: 'left', width: '50%' }}>
      {journalEntriesLoading
        ? Array.from(Array(10).keys()).map(i => <SingleJournalEntrySkeleton key={i} />)
        : <div>
          {(JESort?.field === 'item_type' || JESort?.field === undefined)
            //@ts-ignore
            ? <RclCollapse size="large" defaultActiveKey={defaultOpen} activeKey={activeKey} onChange={(key: string | string[]) => setActiveKey(key)}>
              {itemTypes.map((item_type: any) => (
                <RclCollapsePanel header={item_type} key={item_type}>
                  {journalEntries.map((je: IJournalEntry) => {
                    if (je.item_type === item_type)
                      return <SingleJournalEntry key={je.id} entry={je}></SingleJournalEntry>
                    return null
                  })}
                </RclCollapsePanel>
              ))}
            </RclCollapse>
            : <div className="no-catagory-wrapper">
              {journalEntries.map((je: IJournalEntry) => <SingleJournalEntry entry={je} />)}
            </div>
          }
        </div>
      }
    </RclCol>
  )
}

export default JournalEntriesList
