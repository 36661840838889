import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addMappingsToJE, checkIfJEHasInactiveAccounts, getMainMapping, getInactiveGLAccountsUsed, getJEDefaultOrder } from '../helpers';
import { useAppSelector } from '../hooks/reduxHooks';
import { Account_Class, ACCOUNT_STATUS, Account_Type, Hidden_System_Account, IGeneralLedgerAccount, IJournalEntry, IDepartment, WARNING_LAYOUT, JE_Item_Type, JE_Visible_Invoices, IEmployee } from '../interfaces/IJournalEntry';
import { RootState } from '../store';
import { selectCurrentIntegrationData } from './orgSlice';

const QBO_FILTER_TYPES = ['Accounts Payable', 'Accounts Receivable']

export interface journalEntryState {
  journalEntries: IJournalEntry[];
  generalLedgerAccounts: IGeneralLedgerAccount[];
  departments: IDepartment[];
  employees: IEmployee[];
  generalLedgerAccountsLoading: boolean;
  saveMappingLoading: boolean;
  currentJournalEntry?: IJournalEntry;
  journalEntriesLoading: boolean;
  inactiveGeneralLedgerAccounts: IGeneralLedgerAccount[];
  journalEntryAlert?: {
    layout: WARNING_LAYOUT,
    data: any
  };
  customFilters: {
    search?: string;
    sort?: IJESort;
    filters?: IJEFilter;
  }
  inReplacingAccountModal: boolean;
}
export interface IJESort {
  field?: 'item_type' | 'description';
  order?: 'asc' | 'desc';
}

export interface IJEFilter {
  remove_complete?: boolean;
  remove_without_department_override?: boolean;
  remove_without_employee_override?: boolean;
}

const initialState: journalEntryState = {
  journalEntries: [],
  generalLedgerAccounts: [],
  departments: [],
  employees: [],
  generalLedgerAccountsLoading: true,
  saveMappingLoading: false,
  currentJournalEntry: undefined,
  journalEntriesLoading: true,
  inactiveGeneralLedgerAccounts: [],
  journalEntryAlert: undefined,
  customFilters: { sort: {} },
  inReplacingAccountModal: false,
};

export const journalEntrySlice = createSlice({
  name: 'journalEntry',
  initialState,
  reducers: {
    setInitialLoad: (state, action: PayloadAction<any>) => {
      const { generalLedgerAccounts, mappings, journalEntries, departments, employees } = action.payload
      const inactiveAccounts = generalLedgerAccounts.filter((account: IGeneralLedgerAccount) => account.status !== ACCOUNT_STATUS.ACTIVE)
      state.generalLedgerAccounts = generalLedgerAccounts;
      state.departments = departments;
      state.employees = employees;
      state.inactiveGeneralLedgerAccounts = inactiveAccounts;
      state.journalEntries = journalEntries.map((je: IJournalEntry) => addMappingsToJE(je, mappings));
      const inactive = checkIfJEHasInactiveAccounts(state.journalEntries, state.inactiveGeneralLedgerAccounts)
      state.journalEntryAlert = inactive.length > 0 ? { layout: WARNING_LAYOUT.ACCOUNT_MISSING_OR_REMOVED, data: inactive } : undefined
    },
    setAfterSync: (state, action: PayloadAction<any>) => {
      const { journalEntries, mappings, departments, employees } = action.payload
      state.departments = departments;
      state.employees = employees;
      state.journalEntries = journalEntries.map((je: IJournalEntry) => addMappingsToJE(je, mappings));
    },
    setResetMappings: (state, action: PayloadAction<any>) => {
      const { mappings } = action.payload
      const { journalEntries } = state
      state.journalEntries = journalEntries.map((je: IJournalEntry) => addMappingsToJE(je, mappings));
      state.currentJournalEntry = state.journalEntries.find(i => i.id === state.currentJournalEntry?.id)

      const inactive = checkIfJEHasInactiveAccounts(state.journalEntries, state.inactiveGeneralLedgerAccounts)
      state.journalEntryAlert = inactive.length > 0 ? { layout: WARNING_LAYOUT.ACCOUNT_MISSING_OR_REMOVED, data: inactive } : undefined
    },
    setCurrentJournalEntry: (state, action: PayloadAction<IJournalEntry>) => {
      state.currentJournalEntry = action.payload;
    },
    setJournalEntries: (state, action: PayloadAction<IJournalEntry[]>) => {
      state.journalEntries = action.payload;
    },
    setJESearch: (state, action: PayloadAction<string>) => {
      state.customFilters.search = action.payload
    },
    setJESort: (state, action: PayloadAction<IJESort | undefined>) => {
      state.customFilters.sort = action.payload
    },
    setJEFilters: (state, action: PayloadAction<IJEFilter | undefined>) => {
      state.customFilters.filters = { ...state.customFilters.filters, ...action.payload }
    },
    setSpecificJournalEntry: (state, action: PayloadAction<IJournalEntry>) => {
      const { id } = action.payload;
      const index = state.journalEntries.findIndex((je) => je.id === id);
      state.journalEntries[index] = action.payload;
    },
    setGeneralLedgerAccounts: (state, action: PayloadAction<IGeneralLedgerAccount[]>) => {
      state.generalLedgerAccounts = action.payload;
    },
    setGeneralLedgerAccountsLoading: (state, action: PayloadAction<boolean>) => {
      state.generalLedgerAccountsLoading = action.payload;
    },
    setJournalEntriesLoading: (state, action: PayloadAction<boolean>) => {
      state.journalEntriesLoading = action.payload;
    },
    setJournalEntryAlert: (state, action: PayloadAction<any>) => {
      state.journalEntryAlert = action.payload
    },
    setSaveMappingLoading: (state, action: PayloadAction<boolean>) => {
      state.saveMappingLoading = action.payload;
    },
    setInReplacingAccountModal: (state, action: PayloadAction<boolean>) => {
      state.inReplacingAccountModal = action.payload;
    },
    reset: (state) => {
      state = initialState;
    }
  }
});

export const {
  setInitialLoad,
  setAfterSync,
  setCurrentJournalEntry,
  setSpecificJournalEntry,
  setResetMappings,
  setJournalEntries,
  setJESearch,
  setJESort,
  setJEFilters,
  setGeneralLedgerAccounts,
  setGeneralLedgerAccountsLoading,
  setJournalEntriesLoading,
  setSaveMappingLoading,
  setInReplacingAccountModal,
  reset
} = journalEntrySlice.actions;

export const selectCurrentJournalEntry = (state: RootState) => state.journalEntry.currentJournalEntry;
export const selectJournalEntries = (state: RootState) => state.journalEntry.journalEntries;
export const selectGeneralLedgerAccounts = (state: RootState) => state.journalEntry.generalLedgerAccounts;
export const selectDepartments = (state: RootState) => state.journalEntry.departments;
export const selectEmployees = (state: RootState) => state.journalEntry.employees;
export const selectGeneralLedgerAccountsLoading = (state: RootState) => state.journalEntry.generalLedgerAccountsLoading;
export const selectJournalEntriesLoading = (state: RootState) => state.journalEntry.journalEntriesLoading;
export const selectJournalEntryAlert = (state: RootState) => state.journalEntry.journalEntryAlert;
export const selectSaveMappingLoading = (state: RootState) => state.journalEntry.saveMappingLoading;
export const selectInReplacingAccountModal = (state: RootState) => state.journalEntry.inReplacingAccountModal

export const selectJournalEntriesFilteredOutItemTypes = () => (state: RootState) => {
  const item_type = JE_Item_Type.INVOICE_CHARGE
  const config = JE_Visible_Invoices
  // const additionalHiddenItems = Hide_JE_ITEMS
  const je = state.journalEntry.journalEntries
  const accounts = state.journalEntry.generalLedgerAccounts
  const sort = state.journalEntry.customFilters.sort
  const search = state.journalEntry.customFilters.search?.toLowerCase()

  let result = [...je]
  if (process.env.REACT_APP_ENV !== 'pftest') {
    result = result.filter(x => !(x.item_type === item_type) || (x.item_type === item_type && x.code in config))//.filter(x => !(x.code in additionalHiddenItems))
  }

  if (search) result = result.filter(x => {
    if (x.code.toLowerCase().includes(search) || x.description.toLowerCase().includes(search)) {
      return true
    }

    const mainMapping = getMainMapping(x.mappings)
    const credit_account = mainMapping?.credit_account
    if (credit_account?.name.toLowerCase().includes(search) || credit_account?.gl_code.includes(search)) {
      return true
    }
    const debit_account = accounts.find(acc => acc.id === mainMapping?.allocations[0]?.debit_account_id)
    if (debit_account?.name.toLowerCase().includes(search) || debit_account?.gl_code.includes(search)) {
      return true
    }
    return false
  })
  if (sort) {
    if (sort.field === undefined) {
      const defaultOrder = getJEDefaultOrder()
      result = result
        .sort((a,b) => {
          const defaultA = defaultOrder.findIndex(i => i === a.item_type)
          const defaultB = defaultOrder.findIndex(i => i === b.item_type)
          if (defaultA === -1 && defaultB === -1) return a.item_type > b.item_type ? 1 : -1;
          if (defaultA === -1) return 1;
          if (defaultB === -1) return -1;
          return defaultA - defaultB
        })
    } else {
      if (sort.field === 'item_type') result = result.sort((a, b) => a.description > b.description ? -1 : 1)
      result = result.sort((a, b) => a[sort.field!] > b[sort.field!] ? 1 : -1)
      if (sort.order === 'desc') result = result.reverse()
    }
  }
  return result;
}

export const selectJESort = (state: RootState) => state.journalEntry.customFilters.sort
export const selectJEFilters = (state: RootState) => state.journalEntry.customFilters.filters

export const selectInactiveGLAccountsUsed = (state: RootState) => {
  return getInactiveGLAccountsUsed(state.journalEntry.journalEntries, state.journalEntry.inactiveGeneralLedgerAccounts, state.journalEntry.generalLedgerAccounts)
}

export const selectGeneralLedgerAccountsByAllowedSystemAccount = (state: RootState) => {
  return state.journalEntry.generalLedgerAccounts.filter((account: IGeneralLedgerAccount) => account.system_account in Hidden_System_Account === false)
}

export const selectGeneralLedgerAccountsGroupedByClass = ({ filterBank, filterSystemAccount, sorted }: { filterBank: boolean, filterSystemAccount: boolean, sorted: boolean }) => (state: RootState) => {
  let accounts
  if (filterSystemAccount) accounts = useAppSelector(selectGeneralLedgerAccountsByAllowedSystemAccount)
    else accounts = state.journalEntry.generalLedgerAccounts
  
  const currentIntegration = useAppSelector(selectCurrentIntegrationData);
  if (currentIntegration?.value === 'qbo') {
    accounts = accounts.filter(acc => !QBO_FILTER_TYPES.includes(acc.account_type))
  }
  const accountsByClass = accounts.reduce((acc: any, account: IGeneralLedgerAccount) => {
    const key = account.account_class || Account_Class.OTHER
    if (!acc[key]) {
      acc[key] = []
    }
    if (filterBank && key === Account_Class.ASSET && account.account_type === Account_Type.BANK) {
      return acc;
    }
    acc[key].push(account)
    if (sorted) acc[key].sort((a: any, b: any) => a.name.localeCompare(b.name))
    return acc
  }, {})
  return accountsByClass
}

export const resetMappings: any = (jeApi: any, orgId: number) => async (dispatch: any) => {
  const newMappings = await jeApi.get(`/mappings?organization_id=${orgId}`);
  dispatch(setResetMappings({ mappings: newMappings }))
}

export default journalEntrySlice.reducer;
