import { RclIcon, RclText, RclTitle } from "@risepeopleinc/rcl-react";
import "./IntegrationCard.scss";
import Box from "ui-box";
import { ReactComponent as Icon } from "../../../../../assets/images/kb-article-icon.svg";

interface IIntegrationCardProps {
  value: "xero" | "qbo";
  title: string;
  url: string;
  icon: React.FunctionComponent;
  onSelect: (value: "xero" | "qbo") => void;
  name: string;
}
export const IntegrationCard = ({
  value,
  title,
  url,
  icon,
  onSelect,
  name,
}: IIntegrationCardProps) => {
  return (
    <Box
      className="integration-card-container"
      display="flex"
      gap={8}
      alignItems="flex-start"
      is="label"
      htmlFor={value}
      cursor="pointer"
      width="100%"
    >
      <Box paddingTop={4}>
        <input
          type="radio"
          value={title}
          id={value}
          name={name}
          onClick={() => onSelect(value)}
        />
      </Box>
      <Box
        flexGrow={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" flexDirection="column">
          <RclTitle level={2} style={{fontSize: '20px'}}>{title}</RclTitle>
          <Box display="flex" alignItems="center" gap={4}>
            <RclText
              strong
              style={{ color: "#555df2" }}
              onClick={(e) => {
                if (!e) return;
                e.preventDefault();
                e.stopPropagation();
                window.open(url, "_blank");
              }}
            >
              {url.replace("https://", "").replace("http://", "")}
            </RclText>
            <Icon />
          </Box>
        </Box>
        <RclIcon
          className="integration-card-logo"
          iconName={`${value}Logo`}
          component={icon}
        ></RclIcon>
      </Box>
    </Box>
  );
};
