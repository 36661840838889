import { RclDropdown, RclMenu, RclMenuItem, RclRow, RclSearch, RclTitle, RclText } from '@risepeopleinc/rcl-react'
import deepEqual from "deep-equal"
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { selectDashboardHeaderIsOnTop, setDashboardHeaderIsOnTop } from '../../../../reducers/headerSlice';
import {
  IJESort,
  selectJESort,
  setJESearch,
  setJESort,
  // selectJEFilters
} from '../../../../reducers/journalEntrySlice';
import './DashboardHeader.scss'

// if changing here, you should change on dashboardHeader.scss as well (.dashboard-header-wrapper, top property)
const headerTopDistance = 20;

export const DashboardHeader = () => {
  const [headerDiv, setHeaderDiv] = useState<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch()
  const dashboardHeaderIsOnTop = useAppSelector(selectDashboardHeaderIsOnTop);
  const JESort = useAppSelector(selectJESort)
  // const JEFilters = useAppSelector(selectJEFilters)
  const appliedSortIndex = useMemo(() => {
    if (!JESort) return undefined
    return sortOptions.findIndex(i => deepEqual(i.value, JESort))
  }, [JESort])

  const doSearch = useCallback((e: React.ChangeEvent<any>) => dispatch(setJESearch(e.target.value)), [dispatch])

  const applySort = useCallback((sortOption: string) => {
    if (sortOption === appliedSortIndex?.toString()) dispatch(setJESort(undefined))
    else dispatch(setJESort(sortOptions[parseInt(sortOption)].value))
  }, [dispatch, appliedSortIndex])

  useEffect(() => {
    const handleScroll = () => {
      if (!headerDiv) return

      if (headerDiv.getBoundingClientRect().top <= headerTopDistance) {
        dispatch(setDashboardHeaderIsOnTop(true))
      } else {
        dispatch(setDashboardHeaderIsOnTop(false))
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, [dispatch, headerDiv])

  // const applyFilter = useCallback((filterOption: string) => {
  //   const filter = basicFilterOptions[parseInt(filterOption)]
  //   dispatch(setJEFilters({ [filter.value]: (JEFilters as any)[filter.value] ? false : true }))
  // }, [dispatch, JEFilters])

  return (
    <div ref={ref => setHeaderDiv(ref)} className={`dashboard-header-wrapper ${dashboardHeaderIsOnTop ? 'on-top' : ''}`}>
      <RclTitle level={2}>Payroll Instructions & service charges</RclTitle>
      <div className="search-header">
        <RclSearch className="search-bar" placeholder="Payroll instructions..." onChange={doSearch} />
        <div className='filter-sort-wrapper'>
          {/* <RclDropdown trigger={['click']} overlay={
            <RclMenu onClick={applyFilter} selectable {...{ selectedKeys: [appliedSortIndex?.toString()] }}>
              {basicFilterOptions.map((option, i) => <RclMenuItem key={i}><RclRow><RclCheckbox>{option.name}</RclCheckbox></RclRow></RclMenuItem>)}
            </RclMenu>
          } >
            <RclButton type={appliedSortIndex !== undefined ? 'primary' : 'ghost'}>Filter</RclButton>
          </RclDropdown> */}
          <RclText style={{ color: "white" }} className='filter-sort-text'>Filter</RclText> {/* FILTER HIDDEN UNTIL IMPLEMENTED WITH ABOVE CODE*/}

          <RclDropdown trigger={['click']} overlay={
            <RclMenu onClick={({ key }) => applySort(key)} selectable {...{ selectedKeys: [appliedSortIndex?.toString()] }}>
              {sortOptions.map((option, i) => <RclMenuItem key={i}><RclRow>{option.name}</RclRow></RclMenuItem>)}
            </RclMenu>
          } >
            <RclText className='filter-sort-text'>Sort</RclText>
          </RclDropdown>
        </div>
      </div>
    </div>
  )
}

const sortOptions: { name: string, value: IJESort }[] = [
  { name: "Default order", value: {} },
  { name: 'Payroll instructions & Service charges (a-z)', value: { field: 'description', order: 'asc' } },
  { name: 'Payroll instructions & Service charges (z-a)', value: { field: 'description', order: 'desc' } },
  { name: 'Payroll instructions & Service charges type (a-z)', value: { field: 'item_type', order: 'asc' } },
  { name: 'Payroll instructions & Service charges type (z-a)', value: { field: 'item_type', order: 'desc' } },
]

// const basicFilterOptions: { name: string, value: any }[] = [
//   { name: 'Incomplete', value: 'remove_complete' },
//   // { name: 'Excluded', value: 'excluded' },
//   { name: 'Has department overrides', value: 'remove_without_department_override' },
//   { name: 'Has employee overrides', value: 'remove_without_employee_override' },
// ]
