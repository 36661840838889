import { RclAlert, RclCol } from "@risepeopleinc/rcl-react"
import { useMemo } from "react"
import { useAppSelector } from "../../../../hooks/reduxHooks"
import { IGeneralLedgerAccount, WARNING_LAYOUT } from "../../../../interfaces/IJournalEntry"
import { selectJournalEntryAlert } from "../../../../reducers/journalEntrySlice"
import ReplaceMissingAccountsModal from "../ReplaceMissingAccountsModal/ReplaceMissingAccountsModal"
import { selectCurrentIntegrationData } from "../../../../reducers/orgSlice"
import { renderGLAccount } from "../../../../helpers"

const WarningSection = () => {
  const alertMessage = useAppSelector(selectJournalEntryAlert)
  const currentIntegration = useAppSelector(selectCurrentIntegrationData)

  const buildMessage = useMemo(() => {
    if (!alertMessage) return null;
    switch (alertMessage.layout) {
      case WARNING_LAYOUT.ACCOUNT_MISSING_OR_REMOVED:
        const accounts: IGeneralLedgerAccount[] = alertMessage.data.flatMap((i: any) => i.inactive).filter((v: IGeneralLedgerAccount, i: any, a: IGeneralLedgerAccount[]) => a.findIndex((v2: IGeneralLedgerAccount)=>(v2.id === v.id)) === i)
        return (
          <>
            <span>The following codes have been archived or removed from {currentIntegration?.name}.
              Please remap any connected payroll instructions and service charges before running a new report:
            </span>
            <ul>
              {accounts.map((i: IGeneralLedgerAccount) => <li key={i.gl_code}>{renderGLAccount(i, currentIntegration!.value)}</li>)}
            </ul>
          </>
        )
      default:
        return null
    }
  }, [alertMessage, currentIntegration])

  if (!alertMessage) return null;
  return (
    <RclCol span={24} className='mb-8'>
      <RclAlert 
        message={buildMessage} 
        type="warning" 
        icon 
        showIcon
        description={<ReplaceMissingAccountsModal/>} 
      />
    </RclCol>
  )
}

export default WarningSection
