import './QuickBooks.scss';

interface QuickBooksButtonProps {
  onClick: () => void;
}

export const QuickBooksButton = ({onClick}:QuickBooksButtonProps) => {
  return (
    <button className='quickbooks-button' onClick={onClick}>
      Connect to QuickBooks
    </button>
  )
}