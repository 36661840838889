import { RclText } from "@risepeopleinc/rcl-react";
import "./FurtherConnectionCard.scss";
import Box from "ui-box";

interface IFurtherConnectionCardProps {
  value: string;
  title: string;
  subtitle?: string;
  onSelect: (value: string) => void;
  name: string;
  checked: boolean;
}
export const FurtherConnectionCard = ({
  value,
  title,
  subtitle,
  onSelect,
  name,
  checked,
}: IFurtherConnectionCardProps) => {
  return (
    <Box
      className="further-connection-card-container"
      display="flex"
      gap={8}
      alignItems="flex-start"
      is="label"
      htmlFor={value}
      cursor="pointer"
    >
      <Box>
        <input
          type="radio"
          value={title}
          id={value}
          name={name}
          onClick={() => onSelect(value)}
          checked={checked}
        />
      </Box>
      <Box
        flexGrow={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" flexDirection="column">
          <RclText className="further-connection-card-title">{title}</RclText>
          {!!subtitle && <RclText className="further-connection-card-subtitle">
            {subtitle}
          </RclText>}
        </Box>
      </Box>
    </Box>
  );
};
