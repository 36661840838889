import { useEffect, useMemo, useState } from 'react';
import { RclButton, RclCollapse, RclCollapsePanel, RclList, RclListItem, RclSpace, RclText, RclPagination } from '@risepeopleinc/rcl-react'
import './OverrideMapping.scss'
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { selectEmployees } from '../../../../reducers/journalEntrySlice';
import SelectDebitCredit from '../SelectDebitCredit/SelectDebitCredit';
import { useJEFormContext } from '../../../../hooks/useJEFormContext';
import { isMappingComplete } from '../../../../helpers';
import { selectPrimaryBankAccount } from '../../../../reducers/orgSlice';
import { IEmployee, OVERRIDE_TABS } from '../../../../interfaces/IJournalEntry';
import type { PaginationProps } from 'antd';

interface IPageStateProps {
  currentPage: number,
  pageSize: number,
  minValue: number,
  maxValue: number,
  total: number
}

export const EmployeeMapping = ({ selectedTab }: { selectedTab: any }) => {
  const employees = useAppSelector(selectEmployees)
  const [pageState, setPageState] = useState<IPageStateProps>({
    currentPage: 1,
    pageSize: 10,
    minValue: 0,
    maxValue: 10,
    total: employees.length
  })

  const updatePage: PaginationProps['onShowSizeChange'] = (currentPage, pageSize) => {
    setPageState((prev: IPageStateProps) => ({
      ...prev,
      currentPage: currentPage,
      pageSize: pageSize,
      minValue: (currentPage - 1) * pageSize,
      maxValue: currentPage * pageSize,
    }))
  };

  return (
    <>
      {(selectedTab === OVERRIDE_TABS.employee) &&
        <>
          <RclList className="override-mapping-wrapper" >
            {employees.slice(pageState.minValue, pageState.maxValue).map((employee: any) => (
              <EmployeeMappingItem key={employee.id} employee={employee} />
            ))}
          </RclList>
          <RclPagination
            showSizeChanger
            current={pageState.currentPage}
            // @ts-ignore
            onChange={updatePage}
            total={pageState.total}
          />
        </>
      }
    </>
  )
}

interface IEmployeeMappingItemProps {
  employee: IEmployee
}
const EmployeeMappingItem: React.FC<IEmployeeMappingItemProps> = ({ employee }) => {
  const primaryBankAccount = useAppSelector(selectPrimaryBankAccount)
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const keyName = useMemo(() => `employee${employee.id.toString()}`, [employee.id])
  const { state, setStateForKey } = useJEFormContext()
  const debitCredit = useMemo(() => state[keyName], [state, keyName])

  useEffect(() => {
    if (isMappingComplete(debitCredit))
      setIsExpanded(true);
  }, [debitCredit])

  const handleRemove = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setStateForKey(keyName, { credit: primaryBankAccount, debit: undefined })
    setIsExpanded(false)
  }

  return (
    <RclListItem className='override-mapping-item'>
      <RclCollapse activeKey={isExpanded ? employee.id : undefined} onChange={() => setIsExpanded(s => !s)}>
        <RclCollapsePanel
          key={employee.id}
          showArrow={false}
          {...{ className: 'override-mapping-collapse-panel' }}
          collapsible={isMappingComplete(debitCredit) ? 'disabled' : 'header'}
          header={
            <div className="override-mapping-item-header">
              <RclSpace>
                <RclText className='override-mapping-item-name'>{`${employee.first_name} ${employee.last_name}`}</RclText>
                <RclText className='override-mapping-item-code'>{employee.emp_code}</RclText>
              </RclSpace>
              <RclButton hidden={!isMappingComplete(debitCredit)} onClick={handleRemove} type='link' size='small' danger>Remove</RclButton>
              {!isMappingComplete(debitCredit) && !isExpanded && <RclText className='mapping-override-default-text'>Override default</RclText>}
            </div>
          }
        >
          <SelectDebitCredit keyName={keyName} showSubtitle={false} />
        </RclCollapsePanel>
      </RclCollapse>
    </RclListItem>
  )
}
