import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface globalModalsState {
  xeroReconnectOpen: boolean;
  errorOpen: boolean;
  errorMessage?: string;
  errorType?: string;
}

const initialState: globalModalsState = {
  xeroReconnectOpen: false,
  errorOpen: false,
  errorMessage: undefined,
  errorType: undefined
};

export const globalModals = createSlice({
  name: 'globalModals',
  initialState,
  reducers: {
    setXeroReconnectOpen: (state, action: PayloadAction<boolean>) => {
      state.xeroReconnectOpen = action.payload;
    },
    setErrorOpen: (state, action: PayloadAction<{ message: string, type: string } | false>) => {
      if (action.payload) {
        state.errorMessage = action.payload.message;
        state.errorType = action.payload.type;
        state.errorOpen = true;
      } else {
        state.errorMessage = undefined;
        state.errorType = undefined;
        state.errorOpen = false;
      }
    },
    reset: (state) => {
      state = initialState;
    }
  }
});

export const { setXeroReconnectOpen, setErrorOpen, reset } = globalModals.actions;

export const selectXeroReconnectOpen = (state: RootState) => state.globalModals.xeroReconnectOpen;
export const selectErrorOpen = (state: RootState) => state.globalModals.errorOpen;
export const selectErrorMessage = (state: RootState) => state.globalModals.errorMessage;
export const selectErrorType = (state: RootState) => state.globalModals.errorType;

export default globalModals.reducer;
