import { RclButton, RclModal, RclSpace, RclText, RclTitle } from "@risepeopleinc/rcl-react"
import XeroError from "../../assets/images/xero-error.svg";
import useGlobalModals from "../../hooks/useGlobalModals";
import './XeroErrorModal.scss'

const XeroErrorModal = () => {
  const { errorOpen, setXeroErrorOpenAction, errorMessage, errorType } = useGlobalModals()

  return (
    <RclModal
      wrapClassName="xero-error-modal-wrapper"
      visible={errorOpen}
      closable={false}
      maskClosable={false}
      footer={
        <div className="xero-error-modal-button-wrapper">
          <RclButton key="back" onClick={() => setXeroErrorOpenAction(false)} disabled={false}>
            Close
          </RclButton>
        </div>
      }>
      <RclSpace direction='vertical' className="xero-error-modal-body-wrapper">
        <img src={XeroError} alt='xero-error' />
        <RclTitle level={2}>Error {errorType === 'xero' ? 'from Xero' : ''}</RclTitle>
        <RclText className="xero-error-modal-error-message">{errorMessage}</RclText>
      </RclSpace>
    </RclModal>
  )
}

export default XeroErrorModal
