import { RclDivider, RclDropdown, RclFormItem, RclMenu, RclMenuItem, RclRow, RclSelect, RclSubMenu, RclText, RclOption } from '@risepeopleinc/rcl-react';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { Account_Class, IDefaultDebitCredit, IGeneralLedgerAccount, IMapping, IUpdateMapping, } from '../../../../interfaces/IJournalEntry'
import { selectGeneralLedgerAccounts, selectGeneralLedgerAccountsGroupedByClass, selectInReplacingAccountModal } from '../../../../reducers/journalEntrySlice';
import { useState, useEffect, useRef, useCallback } from 'react';
import { renderGLAccount } from '../../../../helpers';
import { selectCurrentIntegrationData } from '../../../../reducers/orgSlice';

interface IProps {
  placeHolderText?: string
  listOfMappings: IMapping[]
  setMappingsToBeUpdated: React.Dispatch<React.SetStateAction<IUpdateMapping[]>>
  gl_account_id: number
  mappingsToBeUpdated: IUpdateMapping[]
  setCountReplacedAccounts: React.Dispatch<React.SetStateAction<number>>
}

export const ReplaceAccountDebitCreditFormItem: React.FC<IProps> = ({ placeHolderText, listOfMappings, setMappingsToBeUpdated, gl_account_id, mappingsToBeUpdated, setCountReplacedAccounts }) => {
  const { ASSET, EQUITY, EXPENSE, LIABILITY, REVENUE, OTHER } = useAppSelector(selectGeneralLedgerAccountsGroupedByClass({ filterBank: true, filterSystemAccount: true, sorted: true }));
  const accounts = useAppSelector(selectGeneralLedgerAccounts)
  const currentIntegration = useAppSelector(selectCurrentIntegrationData)
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [replaceAccountDebitCredit, setReplaceAccountDebitCredit] = useState<number | undefined>()
  const inReplacingAccountModal = useAppSelector(selectInReplacingAccountModal)

  const replaceAccountDebitCreditRef = useRef<any>()


  const replaceAccounts = useCallback(() => {
    const updated_mappings = listOfMappings.map((mapping: IMapping) => {
      let debitCredit: IDefaultDebitCredit
      let has_mapping_to_be_updated: any = null
      if (mappingsToBeUpdated.length) has_mapping_to_be_updated = mappingsToBeUpdated.find(x => x.id === mapping.id)
      const initialDebitCredit = { credit: mapping.credit_account_id, debit: mapping.allocations[0]?.debit_account_id }

      // updates debitCredit based on if previous updated_mapping already exists
      if (has_mapping_to_be_updated) {
        debitCredit = { credit: has_mapping_to_be_updated.creditAccountId, debit: has_mapping_to_be_updated.allocations[0].debitAccountId }
      } else debitCredit = { credit: mapping.credit_account_id, debit: mapping.allocations[0]?.debit_account_id }

      //replace accounts that matches inactive gl_account_id or was reselected
      if ((debitCredit.credit === gl_account_id || debitCredit.credit === replaceAccountDebitCreditRef.current) && initialDebitCredit.credit === gl_account_id) {
        debitCredit.credit = replaceAccountDebitCredit
      }
      if ((debitCredit.debit === gl_account_id || debitCredit.debit === replaceAccountDebitCreditRef.current) && initialDebitCredit.debit === gl_account_id) {
        debitCredit.debit = replaceAccountDebitCredit
      }

      if (mapping.department_id) {
        return ({ id: mapping?.id, creditAccountId: debitCredit.credit, journalEntryItemId: mapping.journal_entry_item_id, departmentId: mapping.department_id, allocations: [{ id: mapping?.allocations[0]?.id, percentage: 100, debitAccountId: debitCredit.debit }] })
      } else if (mapping.employee_id) {
        return ({ id: mapping?.id, creditAccountId: debitCredit.credit, journalEntryItemId: mapping.journal_entry_item_id, employeeId: mapping.employee_id, allocations: [{ id: mapping?.allocations[0]?.id, percentage: 100, debitAccountId: debitCredit.debit }] })
      }
      else
        return ({ id: mapping?.id, creditAccountId: debitCredit.credit, journalEntryItemId: mapping.journal_entry_item_id, allocations: [{ id: mapping?.allocations[0]?.id, percentage: 100, debitAccountId: debitCredit.debit }] })
    })

    setMappingsToBeUpdated((prev: any) => {
      if (prev.length) {
        let listOfExistingIDs = prev.map((x: any) => x.id)
        listOfExistingIDs.filter((item: number[], index: number) => listOfExistingIDs.indexOf(item) === index);
        let newData = prev
        updated_mappings.forEach(updated_mapping => {
          if (listOfExistingIDs.includes(updated_mapping.id)) {
            const index = prev.findIndex((x: any) => x.id === updated_mapping.id)
            newData[index] = updated_mapping
          } else {
            newData.push(updated_mapping)
          }
        })
        return (newData)
      }
      else return updated_mappings
    })
  }, [gl_account_id, listOfMappings, mappingsToBeUpdated, replaceAccountDebitCredit, setMappingsToBeUpdated])

  useEffect(() => {
    if (replaceAccountDebitCredit) {
      replaceAccounts()
    }
    if (replaceAccountDebitCreditRef.current === undefined && replaceAccountDebitCredit) {
      setCountReplacedAccounts((prev: any) => prev + 1)
      replaceAccountDebitCreditRef.current = replaceAccountDebitCredit
    }
  }, [replaceAccountDebitCredit, replaceAccounts, setCountReplacedAccounts])

  const saveDebitOrCredit = (id: number) => {
    setReplaceAccountDebitCredit(id)
  }

  const onSelect = (value: string) => {
    saveDebitOrCredit(JSON.parse(value).id)
  }

  const onSearch = (value: string) => {
    if (value === "") setIsSearching(false)
    else setIsSearching(true)
  }

  const getNameAndCodeFromID = (id?: number) => {
    if (!id) return undefined
    const acc = accounts.find(x => x.id === id)
    if (!acc) return '';
    return renderGLAccount(acc, currentIntegration!.value)
  }

  useEffect(() => {
    if (!inReplacingAccountModal) {
      replaceAccountDebitCreditRef.current = undefined
      setReplaceAccountDebitCredit(undefined)
      setCountReplacedAccounts(0)
    }
  }, [inReplacingAccountModal, setCountReplacedAccounts])

  return (
    <RclFormItem>
      <RclDropdown {...{ name: 'gl_accounts' } as any} trigger={['click']}
        overlayStyle={{ display: `${isSearching ? 'none' : ''}` }}
        onOpenChange={(open: boolean) => { if (!open) setTimeout(() => setIsSearching(false), 300) }}
        overlay={
          <RclMenu>
            <RclDivider className="mb-0 mt-0"></RclDivider>
            <AccountsDropdownSubmenu accounts={EXPENSE} title={Account_Class.EXPENSE} saveDebitOrCredit={saveDebitOrCredit} />
            <AccountsDropdownSubmenu accounts={LIABILITY} title={Account_Class.LIABILITY} saveDebitOrCredit={saveDebitOrCredit} />
            <RclDivider className="mb-0 mt-0"></RclDivider>
            <AccountsDropdownSubmenu accounts={ASSET} title={Account_Class.ASSET} saveDebitOrCredit={saveDebitOrCredit} />
            <AccountsDropdownSubmenu accounts={EQUITY} title={Account_Class.EQUITY} saveDebitOrCredit={saveDebitOrCredit} />
            <AccountsDropdownSubmenu accounts={REVENUE} title={Account_Class.REVENUE} saveDebitOrCredit={saveDebitOrCredit} />
            <AccountsDropdownSubmenu accounts={OTHER} title={Account_Class.OTHER} saveDebitOrCredit={saveDebitOrCredit} />
          </RclMenu>
        }
      >
        <RclSelect
          onSearch={onSearch}
          onSelect={onSelect}
          dropdownStyle={{ display: `${isSearching ? '' : 'none'}` }}
          placeholder={placeHolderText}
          value={getNameAndCodeFromID(replaceAccountDebitCredit)}
        >
          {isSearching && accounts.map((gl: IGeneralLedgerAccount) =>
            <RclOption key={gl.id} value={JSON.stringify(gl)}>
              {renderGLAccount(gl, currentIntegration!.value)}
            </RclOption>)
          }
        </RclSelect>
      </RclDropdown>
    </RclFormItem>
  )
}

const AccountsDropdownSubmenu = ({ accounts, title, saveDebitOrCredit, ...props }: { accounts: IGeneralLedgerAccount[], title: string, saveDebitOrCredit: any }) => {
  return (
    <RclSubMenu
      title={title}
      key={title}
      {...{ ...props, eventKey: title, popupClassName: 'accountsContainer' }}
    >
      {accounts?.map((singleAccount: IGeneralLedgerAccount) => {
        return <RclMenuItem
          {...{ ...props, eventKey: singleAccount.gl_code }}
          key={singleAccount.gl_code}>
          <RclRow
            onClick={() => {
              saveDebitOrCredit(singleAccount.id)
            }}
          >
            <RclText className="account-info-wrapper">
              <span>{singleAccount.name}</span>
              <span className="subtitle">{singleAccount.gl_code}</span>
            </RclText>
          </RclRow>
        </RclMenuItem>
      })}
    </RclSubMenu>
  )
}
