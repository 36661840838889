import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOrganization } from '../interfaces/IOrganization';
import { RootState } from '../store';

import { ReactComponent as XeroLogo } from "../assets/images/xero-logo.svg";
import { ReactComponent as QboLogo } from "../assets/images/qbo-logo.svg";
import { TargetType } from '../pages/Dashboard/components/RunReportModal/RunReportModal';

export interface orgState {
  orgs: IOrganization[];
  brokenOrgs: number[];
  integrationConnection?: 'xero' | 'qbo';
  currentOrgId?: number;
  orgsLoading: boolean;
  orgSelectError?: string;
}

const initialState: orgState = {
  orgs: [],
  brokenOrgs: [],
  integrationConnection: undefined,
  currentOrgId: undefined,
  orgsLoading: true,
  orgSelectError: undefined,
};

export const orgSlice = createSlice({
  name: 'org',
  initialState,
  reducers: {
    setCurrentOrg: (state, action: PayloadAction<IOrganization>) => {
      localStorage.setItem('current_org', action.payload.id.toString())
      state.currentOrgId = action.payload.id;
      state.orgs = state.orgs.map(org => {
        if (org.id === action.payload.id) {
          const orgToSet = { ...action.payload }
          if (orgToSet.is_connected_to_xero === undefined) {
            orgToSet.is_connected_to_xero = orgToSet.xero_org_connections?.length > 0;
          }
          if (orgToSet.is_connected_to_qbo === undefined) {
            orgToSet.is_connected_to_qbo = orgToSet.qbo_org_connections?.length > 0;
          }
          return orgToSet;
        }
        return org;
      })
    },
    setOrgs: (state, action: PayloadAction<IOrganization[]>) => {
      state.orgs = action.payload;
    },
    setBrokenOrgs: (state, action: PayloadAction<number[]>) => {
      state.brokenOrgs = [...state.brokenOrgs, ...action.payload];
    },
    removeBrokenOrgFromList: (state, action: PayloadAction<number>) => {
      state.brokenOrgs = state.brokenOrgs.filter(orgId => orgId !== action.payload);
    },
    setPrimaryBankAccount: (state, action: PayloadAction<number | undefined>) => {
      state.orgs = state.orgs.map(org => {
        if (org.id === state.currentOrgId) {
          return { ...org, default_credit_account_id: action.payload }
        }
        return org;
      })
    },
    setOrgsLoading: (state, action: PayloadAction<boolean>) => {
      state.orgsLoading = action.payload;
    },
    setOrgSync: (state, action: PayloadAction<{ orgId: number, synchronized: boolean, synchronizing: boolean }>) => {
      state.orgs = state.orgs.map(org => {
        if (org.id === action.payload.orgId) {
          return { ...org, synchronized: action.payload.synchronized, synchronizing: action.payload.synchronizing }
        }
        return org;
      })
    },
    setOrgSelectError: (state, action: PayloadAction<string | undefined>) => {
      state.orgSelectError = action.payload;
    },
    reset: (state) => {
      localStorage.removeItem('current_org')

      state.orgs = initialState.orgs;
      state.brokenOrgs = initialState.brokenOrgs;
      state.currentOrgId = initialState.currentOrgId;
      state.orgsLoading = initialState.orgsLoading;
      state.orgSelectError = initialState.orgSelectError;
    }
  }
});

export const { setCurrentOrg, setOrgs, setOrgsLoading, setPrimaryBankAccount, setOrgSync, setBrokenOrgs, removeBrokenOrgFromList, setOrgSelectError, reset } = orgSlice.actions;

export const selectCurrentOrg = (state: RootState) => state.org.orgs.find(org => org.id === state.org.currentOrgId);

export const selectCurrentIntegrationData = (state: RootState) => {
  const currentOrg = selectCurrentOrg(state);
  if (!currentOrg) return;
  const isXero = currentOrg.xero_org_connections?.length > 0;
  const isQbo = currentOrg.qbo_org_connections?.length > 0;
  if (!isXero && !isQbo) return;
  return {
    value: isXero ? 'xero' : 'qbo',
    name: isXero ? 'Xero' : 'QuickBooks Online',
    icon: isXero ? XeroLogo : QboLogo,
    disconnectEndpoint: isXero ? '/xero_disconnect' : '/qbo_disconnect',
    targetType: isXero ? TargetType.Xero : TargetType.QBO,
  }
}

export const selectOrgIsConfigured = (state: RootState) => selectCurrentOrg(state)?.default_credit_account_id
export const selectOrgs = (state: RootState) => state.org.orgs;
export const selectOrgsLoading = (state: RootState) => state.org.orgsLoading;
export const selectPrimaryBankAccount = (state: RootState) => selectCurrentOrg(state)?.default_credit_account_id;
export const selectIsFirstIntegration = (state: RootState) => {
  const currentOrg = selectCurrentOrg(state);
  if (!currentOrg) return;
  const otherOrgHasIntegration = state.org.orgs.some(org => org.id !== currentOrg.id && (org.xero_org_connections?.length > 0 || org.qbo_org_connections?.length > 0));
  const currentOrgIsBroken = state.org.brokenOrgs.includes(currentOrg.id);
  return !otherOrgHasIntegration && !currentOrgIsBroken;
}
export const selectBrokenOrgs = (state: RootState) => state.org.brokenOrgs;
export const selectOrgSelectError = (state: RootState) => state.org.orgSelectError;

export default orgSlice.reducer;
