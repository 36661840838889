import { RclRow, RclCol } from "@risepeopleinc/rcl-react"
import { useCallback, useEffect, useMemo, useState } from "react";

import Skeleton from "react-loading-skeleton";
import { useAppSelector } from "../../../../hooks/reduxHooks";
import { IDefaultDebitCredit } from "../../../../interfaces/IJournalEntry";
import { selectCurrentJournalEntry, selectJournalEntriesLoading } from "../../../../reducers/journalEntrySlice";
import { selectPrimaryBankAccount } from "../../../../reducers/orgSlice";
import { DebitCreditFormItem } from "../DebitCreditFormItem/DebitCreditFormItem";
import { useJEFormContext } from "../../../../hooks/useJEFormContext";

import './SelectDebitCredit.scss'

export enum MappingType {
  Main,
  Department,
  Employee
}

const SelectDebitCredit = ({ keyName, showSubtitle = true }: { keyName: string, showSubtitle?: boolean }) => {
  const selectedEntry = useAppSelector(selectCurrentJournalEntry)
  const journalEntryLoading = useAppSelector(selectJournalEntriesLoading)
  const primaryBankAccount = useAppSelector(selectPrimaryBankAccount)
  const [isEditCredit, setIsEditCredit] = useState<boolean>(true)
  const { state, initialState, setStateForKey } = useJEFormContext()
  const debitCredit = useMemo(() => state[keyName], [state, keyName])
  const initialDebitCredit = useMemo(() => initialState[keyName], [initialState, keyName])

  const setDebitCredit = useCallback((v: IDefaultDebitCredit) => {
    setStateForKey(keyName, { ...debitCredit, ...v })
  },
    [debitCredit, setStateForKey, keyName])

  useEffect(() => {
    if (selectedEntry && debitCredit.credit === primaryBankAccount) setIsEditCredit(false);
    else setIsEditCredit(true);
  }, [selectedEntry, debitCredit.credit, primaryBankAccount])

  const removeCreditChanges = useCallback(() => {
    setDebitCredit({ debit: debitCredit.debit, credit: primaryBankAccount })
    setIsEditCredit(false)
  }, [setDebitCredit, primaryBankAccount, debitCredit.debit])


  if (journalEntryLoading) {
    return (
      <RclCol flex={"auto"}>
        <Skeleton width={'100%'} height={268} />
      </RclCol>
    )
  }
  return (
    <div>
      <div className='select-debit-credit-form-wrapper'>
        <DebitCreditFormItem
          title="Default debit account"
          subtitle={`${showSubtitle? "where are the funds going?" : "" }`}
          isDebit
          debitCredit={debitCredit}
          saveButtonLoading={false}
          setDebitCredit={setDebitCredit}
        />
        {isEditCredit
          ?
          <>
            <DebitCreditFormItem
              title="Default credit account"
              subtitle={`${showSubtitle? "where are the funds coming from" : "" }`}
              isDebit={false}
              debitCredit={debitCredit}
              saveButtonLoading={false}
              setDebitCredit={setDebitCredit}
            />
            {(primaryBankAccount !== initialDebitCredit.credit && debitCredit.credit !== primaryBankAccount)
              && <RclRow onClick={removeCreditChanges}>
                <p className="subtext remove-button">Remove</p>
              </RclRow>}
          </>
          :
          <RclRow>
            <RclCol><p style={{ color: "grey" }} className="subtext">Funds will be taken from your primary bank account.</p></RclCol>
            <RclCol
              className="change-credit-account ml-6"
              onClick={() => setIsEditCredit(true)}
            >
              <p className="subtext change-credit-account-button">Change credit account</p>
            </RclCol>
          </RclRow>
        }
      </div>
    </div>
  )
}
export default SelectDebitCredit;