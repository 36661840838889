import axios from 'axios'
import { environment } from '../environments/environment';

const jeApi = axios.create({ baseURL: environment.JE_API_URL })

const setAuthorizationHeader = (token: string) => {
  localStorage.setItem('je_token', token);
}

export default jeApi
export { setAuthorizationHeader }
