import { RclButton, RclFormInstance, RclIcon, RclModal, RclSpace, RclText, RclTitle } from "@risepeopleinc/rcl-react"
import BalancedReport from "../../../../assets/images/balanced-report.svg"
import UnbalancedReport from "../../../../assets/images/unbalanced-report.svg"
import { ReactComponent as Download } from "../../../../assets/images/download.svg";
import ArrowRight from "../../../../assets/images/arrow-right";
import './BalanceResultsModal.scss'
import { useMemo, useState } from "react";
import { ReportType } from "../RunReportModal/RunReportModal";
import KBArticleLink from "../KBArticleLink/KBArticleLink";
import { useAppSelector } from "../../../../hooks/reduxHooks";
import { selectCurrentIntegrationData } from "../../../../reducers/orgSlice";

interface IProps {
  visible: boolean
  onClose: () => void
  balanceResult: { invoice_total: number, mapping_total: number }
  submitReport: () => Promise<void>
  downloadReport: () => Promise<void>
  payRunOrDate: ReportType
  form: React.MutableRefObject<RclFormInstance<any> | undefined>
}

const BalanceResultsModal: React.FC<IProps> = ({ visible, onClose, balanceResult, submitReport, downloadReport, payRunOrDate, form }) => {
  const isBalanced = useMemo(() => balanceResult.invoice_total === balanceResult.mapping_total, [balanceResult])
  const diff = useMemo(() => balanceResult.mapping_total - balanceResult.invoice_total, [balanceResult])
  const currentIntegration = useAppSelector(selectCurrentIntegrationData)
  const [isSending, setIsSending] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)

  const formValues = useMemo(() => {
    if (!visible || !form.current) return null
    const { payRunNumber, payRunYear, dateRange, targetType } = form.current.getFieldsValue()
    return { payRunNumber, payRunYear, dateRange, targetType }
  }, [visible, form])

  const reportName = useMemo(() => {
    if (!formValues) return ''
    if (payRunOrDate === ReportType.PayRun) {
      return `#${formValues.payRunNumber}-${formValues.payRunYear}`
    } else {
      return `${formValues.dateRange[0].format('DD-MM-YYYY')} to ${formValues.dateRange[1].format('DD-MM-YYYY')}`
    }

  }, [formValues, payRunOrDate])

  const overrideSubmitToXero = async () => {
    setIsSending(true)
    await submitReport()
    setIsSending(false)
  }

  const overrideDownloadReport = async () => {
    setIsDownloading(true)
    await downloadReport()
    setIsDownloading(false)
  }

  return (
    <RclModal
      wrapClassName="balance-modal-wrapper"
      closable={false}
      onClose={() => onClose()}
      visible={visible}
      footer={
        <div className="balance-modal-button-wrapper">
          <div className="balance-modal-button-wrapper-main-section">
            <RclButton key="back" onClick={() => onClose()} >
              Close
            </RclButton>
            {isBalanced
              ? <RclButton loading={isSending} onClick={overrideSubmitToXero} type="primary" icon={<Download />}>
                Send journal entry to {currentIntegration?.name}
                <RclIcon iconName="arrow-right" component={ArrowRight} />
              </RclButton>
              : <RclButton loading={isDownloading} onClick={overrideDownloadReport} type="primary">
                Download Report
              </RclButton>}
          </div>
          {!isBalanced && <RclButton loading={isSending} onClick={overrideSubmitToXero} type="ghost">
            Send journal entry to {currentIntegration?.name} anyway
            <RclIcon iconName="arrow-right" component={ArrowRight} />
          </RclButton>}
        </div>
      }
    >
      {isBalanced
        ? <RclSpace direction='vertical' className="balance-modal-body-wrapper">
          <img src={BalancedReport} alt='balanced-report' />
          <RclTitle level={2}>Everything looks balanced!</RclTitle>
          <RclText>
            The report balance for pay run <RclText strong>{reportName}</RclText> <RclText>matches the related invoice. </RclText>
            <RclText onClick={isDownloading ? () => { } : overrideDownloadReport} className="balance-modal-link">
              You can download the report here.
              {isDownloading && <RclIcon iconName="Spin" style={{ color: '#555DF2' }} />}
            </RclText>
          </RclText>

        </RclSpace>
        :
        <RclSpace direction='vertical' className="balance-modal-body-wrapper">
          <img src={UnbalancedReport} alt='unbalanced-report' />
          <RclTitle level={2}>Something doesn't add up...</RclTitle>
          <RclText className="balance-modal-message">
            This report balance is <b>${Math.abs(diff).toFixed(2)}</b> {diff > 0 ? 'more' : 'less'} than the related invoice.
            We recommend reading our <KBArticleLink wording="knowledge base article" link='https://support.risepeople.com/portal/en/kb/articles/troub#Troubleshooting' /> or downloading the report to double-check mappings before sending to {currentIntegration?.name}.
          </RclText>
        </RclSpace>
      }
    </RclModal>
  )
}

export default BalanceResultsModal
