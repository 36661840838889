import {
    RclCol,
    RclButton,
    RclRow,
    RclModal,
    RclList,
    RclListItem,
    RclText
  } from "@risepeopleinc/rcl-react"

import { useEffect, useState, useMemo } from "react"
import { ReplaceAccountDebitCreditFormItem } from "../ReplaceAccountDebitCreditFormItem.tsx/ReplaceAccountDebitCreditFormItem"
import './ReplaceMissingAccountsModal.scss'
import arrowRight from '../../../../assets/images/arrow-right-dark.svg'
import { IMapping, IUpdateMapping } from "../../../../interfaces/IJournalEntry"
import { useAppSelector, useAppDispatch } from "../../../../hooks/reduxHooks"
import { selectGeneralLedgerAccounts, setSaveMappingLoading,resetMappings, setInReplacingAccountModal, selectInactiveGLAccountsUsed, selectJournalEntries } from "../../../../reducers/journalEntrySlice"
import { useJEFormContext } from '../../../../hooks/useJEFormContext';
import useJeApi from "../../../../hooks/useJeApi"
import { selectCurrentIntegrationData, selectCurrentOrg } from "../../../../reducers/orgSlice"
import { getJEFromId } from "../../../../helpers"
import useCustomToast from "../../../../hooks/useCustomToast"

interface IformattedData  {
    [key: number] : IUpdateMapping[]
}

const ReplaceMissingAccountsModal = () => {
    const [open, setOpen] = useState(false)
    const dispatch = useAppDispatch();
    const { put, get } = useJeApi()
    const inactiveGLAccountsUsed = useAppSelector(selectInactiveGLAccountsUsed)
    const currentOrg = useAppSelector(selectCurrentOrg)
    const currentIntegration = useAppSelector(selectCurrentIntegrationData)
    const { state, setInitialState } = useJEFormContext()
    const [countReplacedAccounts, setCountReplacedAccounts] = useState<any>(0)
    const [mappingsToBeUpdated, setMappingsToBeUpdated] = useState<IUpdateMapping[]>([])
    const journalEntries = useAppSelector(selectJournalEntries)
    const { showToastSuccess } = useCustomToast()

    const addInUnchangedMappings = (formattedData: IformattedData) => {
        for (const je_id in formattedData) {
            const JE = getJEFromId(parseInt(je_id), journalEntries)
            const mappingIDs = formattedData[je_id].map((x:any) => x.id)
            if (JE?.mappings) {
                JE.mappings.forEach(x => {
                    if (!mappingIDs.includes(x.id)) {
                        const itemsToAdd: IUpdateMapping = { id: x?.id, creditAccountId: x.credit_account_id, journalEntryItemId: x.journal_entry_item_id, allocations: [{ id: x?.allocations[0]?.id, percentage: 100, debitAccountId: x.allocations[0]?.debit_account_id }]}
                        if (x.department_id) {
                            itemsToAdd["departmentId"] = x.department_id
                        } else if (x.employee_id)
                            itemsToAdd["employeeId"] = x.employee_id
                        formattedData[je_id].push(itemsToAdd)
                    }
                })
            }
        }
        return formattedData
    }

    const canReplace = useMemo(()=>{
        return (inactiveGLAccountsUsed.length === countReplacedAccounts)
    },[countReplacedAccounts, inactiveGLAccountsUsed.length])

    const saveMultipleJEMappings = () => {
        const formattedData: IformattedData = mappingsToBeUpdated.reduce((item:any, mapping:any) => {
            const key = mapping.journalEntryItemId
            if (!item[key]) {
                item[key] = []
              }
            item[key].push(mapping)
            return item
        },{})
        addInUnchangedMappings(formattedData)

        const requests: any = []
        if (formattedData) {
          Object.entries(formattedData).forEach(async ([k,v]) => {
            requests.push(put(`create_update_multiple_mappings?organization_id=${currentOrg?.id}&journal_entry_item_id=${k}`, v));
            })
            Promise.all(requests)
            .then(async() => {
                await dispatch(resetMappings({ get }, currentOrg?.id));
                setInitialState(state);
                dispatch(setSaveMappingLoading(false))
                dispatch(setInReplacingAccountModal(false))
                setMappingsToBeUpdated([])
                showToastSuccess('Accounts have been successfully replaced')
            })
        }
    }

    useEffect(() => {
        if (!open) {
            dispatch(setInReplacingAccountModal(false))
            setMappingsToBeUpdated([])
        }
    },[open, dispatch])

    return (
        <>
        <RclRow justify="end">
            <RclText
                style={{cursor: "pointer", fontWeight: 600}}
                onClick={() => {
                    dispatch(setInReplacingAccountModal(true))
                    setOpen(true)
                }}>
                Replace Missing Account
            </RclText>
        </RclRow>
        <RclModal visible={open} title="Replace Missing Accounts"
            onCancel={() => setOpen(false)}
            footer={[
                <RclButton key="back"
                    disabled={false}
                    onClick={() => setOpen(false)}>
                    Cancel
                </RclButton>,
                <RclButton
                    disabled={!canReplace}
                    key="link"
                    type="primary"
                    onClick={() => {
                        saveMultipleJEMappings()
                    }}
                    >
                    Replace accounts
                </RclButton>
            ]}>
            <RclRow className="modal-body">
                <RclRow><p>The following accounts have been archived or removed from {currentIntegration?.name} Please select new accounts to replace them.</p></RclRow>
                <RclList bordered={false}>
                    {inactiveGLAccountsUsed.map((inactiveGLAccountsUsed: any) =>
                        <ReplaceMissingAccountItem inactiveGLAccountsUsed={inactiveGLAccountsUsed} setMappingsToBeUpdated={setMappingsToBeUpdated} mappingsToBeUpdated={mappingsToBeUpdated} setCountReplacedAccounts={setCountReplacedAccounts}></ReplaceMissingAccountItem>
                    )}
                </RclList>
            </RclRow>
        </RclModal>
        </>
    )
}

interface inactiveGLAccountsUsed{
    gl_account_id: number,
    listOfMappings: IMapping[]
}

interface IReplaceMissingAccountItemProps{
    inactiveGLAccountsUsed: inactiveGLAccountsUsed
    setMappingsToBeUpdated: React.Dispatch<React.SetStateAction<IUpdateMapping[]>>
    mappingsToBeUpdated: IUpdateMapping[]
    setCountReplacedAccounts: React.Dispatch<React.SetStateAction<number>>
}

const ReplaceMissingAccountItem: React.FC<IReplaceMissingAccountItemProps> = ({inactiveGLAccountsUsed, setMappingsToBeUpdated, mappingsToBeUpdated, setCountReplacedAccounts}) => {
    const generalLedgerAccounts = useAppSelector(selectGeneralLedgerAccounts)
    const currentIntegration = useAppSelector(selectCurrentIntegrationData)
    const getGLCodeFromID = (id:number) => {
        const res = generalLedgerAccounts.find(x => x.id === id)
        if (currentIntegration?.value === 'xero') {
            return res?.gl_code
        } else {
            return res?.acct_num || ''
        }
    }
    const getGLNameFromID = (id:number) => {
        const res = generalLedgerAccounts.find(x => x.id === id)
        return res?.name
    }

    return (
        <RclListItem
        className="inactive-account-row">
            <RclCol className="je-and-account-info-box">
                <p className="account-name">{getGLNameFromID(inactiveGLAccountsUsed.gl_account_id)}</p>
                <p className="account-code">{getGLCodeFromID(inactiveGLAccountsUsed.gl_account_id)}</p>
            </RclCol>
            <RclCol>
                <div className="new-account-box mb-16">
                    <img src={arrowRight} alt='arrow-right' />
                    <ReplaceAccountDebitCreditFormItem
                        placeHolderText={" - New Account - "}
                        listOfMappings={inactiveGLAccountsUsed.listOfMappings}
                        setMappingsToBeUpdated={setMappingsToBeUpdated}
                        gl_account_id = {inactiveGLAccountsUsed.gl_account_id}
                        mappingsToBeUpdated={mappingsToBeUpdated}
                        setCountReplacedAccounts={setCountReplacedAccounts}
                    />
                </div>
            </RclCol>
        </RclListItem>
    )
}

export default ReplaceMissingAccountsModal

