import { useEffect, useMemo } from "react"
import { Outlet, useLocation } from "react-router-dom"
import './Root.scss'
import { IOrganization } from '../../interfaces/IOrganization'
import riseLogo from '../../assets/images/rise-logo.svg'
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks"
import { reset, selectOrgsLoading, setCurrentOrg, setOrgs, setOrgsLoading } from "../../reducers/orgSlice"
import useJeApi from "../../hooks/useJeApi"
import XeroReconnectModal from "../../components/XeroReconnectModal/XeroReconnectModal"
import XeroErrorModal from "../../components/XeroErrorModal/XeroErrorModal"
import { selectDashboardHeaderIsOnTop } from "../../reducers/headerSlice"
import { RclText } from "@risepeopleinc/rcl-react"
import { setJwt } from "../../reducers/userSlice"
import { SkeletonTheme } from "react-loading-skeleton"
import SelectOrg from "../Dashboard/components/SelectOrg/SelectOrg"

const Root = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const orgsLoading = useAppSelector(selectOrgsLoading);
  const dashboardHeaderIsOnTop = useAppSelector(selectDashboardHeaderIsOnTop);
  const { authenticated, get } = useJeApi();

  const shouldHide = useMemo(() => {
    if (location.pathname === '/sign-in-successful') return true
    else return false
  }, [location.pathname])

  const signOut = () => {
    localStorage.removeItem('je_token')
    dispatch(setJwt(""))
    dispatch(reset())
  }

  useEffect(() => {
    (async () => {
      if (authenticated) {
        const fetchOrgs = await get<IOrganization[]>('/organizations');
        dispatch(setOrgs(fetchOrgs));
        const orgsWithConnection = fetchOrgs.filter((org: IOrganization) => org.xero_org_connections?.length || org.qbo_org_connections?.length);
        orgsWithConnection.sort((a: any, b: any) => b.updated_at.localeCompare(a.updated_at));
        const hasFromLocalStorage = localStorage.getItem('current_org')
        const orgFromLocalStorage = hasFromLocalStorage ? fetchOrgs.find(org => org.id.toString() === hasFromLocalStorage) : null;
        if (!!fetchOrgs.length) dispatch(setCurrentOrg(orgFromLocalStorage || orgsWithConnection[0] || fetchOrgs[0]));
        dispatch(setOrgsLoading(false));
      }
    })()
  }, [authenticated, dispatch, get])

  if (authenticated && orgsLoading) return <>Loading...</>

  return (
    <div className="root-app">
      <SkeletonTheme baseColor="#E8EAF2" highlightColor="#d9dbfc">
        <XeroReconnectModal />
        <XeroErrorModal />
        <div className={`${dashboardHeaderIsOnTop ? 'dashboard-header-on-top root-main-header-large' : 'root-main-header '} `}>
          <img src={riseLogo} className="root-rise-logo" alt='rise-logo' />
          {!!authenticated &&
            <div className='root-rise-right'>
              <SelectOrg headerMode />
              {shouldHide ? null : <div className="root-sign-out"
                onClick={signOut}
              >
                <RclText>Sign Out</RclText>
              </div>}
            </div>}
        </div>
        <div className="root-outlet-wrapper">
          <Outlet />
        </div>
      </SkeletonTheme>
    </div>
  )
}

export default Root
