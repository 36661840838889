import { RclDivider, RclDropdown, RclFormItem, RclMenu, RclMenuItem, RclRow, RclSelect, RclSubMenu, RclText, RclOption } from '@risepeopleinc/rcl-react';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { Account_Class, IDefaultDebitCredit, IGeneralLedgerAccount } from '../../../../interfaces/IJournalEntry'
import { selectGeneralLedgerAccounts, selectGeneralLedgerAccountsGroupedByClass } from '../../../../reducers/journalEntrySlice';
import { useState, useCallback, useMemo } from 'react';
import { renderGLAccount } from '../../../../helpers';
import { selectCurrentIntegrationData } from '../../../../reducers/orgSlice';

interface IProps {
  debitCredit: IDefaultDebitCredit,
  setDebitCredit: (v: IDefaultDebitCredit) => void
  saveButtonLoading: boolean
  title: string
  subtitle: string
  isDebit: boolean
}

export const DebitCreditFormItem: React.FC<IProps> = ({ setDebitCredit, debitCredit, saveButtonLoading, title, subtitle, isDebit }) => {
  const { ASSET, EQUITY, EXPENSE, LIABILITY, REVENUE, OTHER } = useAppSelector(selectGeneralLedgerAccountsGroupedByClass({ filterBank: true, filterSystemAccount: true, sorted: true }));
  const accounts = useAppSelector(selectGeneralLedgerAccounts)
  const currentIntegration = useAppSelector(selectCurrentIntegrationData)
  const accountsToSearch = useMemo(() => {
    return [...(ASSET ?? []), ...(EQUITY ?? []), ...(EXPENSE ?? []), ...(LIABILITY ?? []), ...(REVENUE ?? []), ...(OTHER ?? [])]
  }, [ASSET, EQUITY, EXPENSE, LIABILITY, OTHER, REVENUE])
  const [isSearching, setIsSearching] = useState<boolean>(false)

  const saveDebitOrCredit = (id: number) => {
    if (isDebit) {
      setDebitCredit({ debit: id })
    }
    else {
      setDebitCredit({ credit: id })
    }
  }

  const onSearch = (value: string) => {
    if (value === "") setIsSearching(false)
    else setIsSearching(true)
  }
  const onSelect = (value: string) => {
    saveDebitOrCredit(JSON.parse(value).id)
  }

  const getNameAndCodeFromID = (id?: number) => {
    if (!id) return undefined
    const acc = accounts.find(x => x.id === id)
    if (!acc) return '';
    return renderGLAccount(acc, currentIntegration!.value)
  }
  const clearDebit = useCallback(() => {
    setDebitCredit({ debit: undefined })
  }, [setDebitCredit])

  return (
    <RclFormItem>
      <RclRow>
        <p className="subtext">{title}</p>
        <p style={{ color: "grey" }} className="subtext">{subtitle}</p>
      </RclRow>
      <RclDropdown {...{ name: 'gl_accounts' } as any} trigger={['click']}
        getPopupContainer={() => document.querySelector('.select-debit-credit-form-wrapper')}
        overlayStyle={{ display: `${isSearching ? 'none' : ''}` }}
        onOpenChange={(open: boolean) => { if (!open) setTimeout(() => setIsSearching(false), 300) }}
        overlay={
          <RclMenu>
            <AccountsDropdownSubmenu accounts={EXPENSE} title={Account_Class.EXPENSE} isDebit={isDebit} saveDebitOrCredit={saveDebitOrCredit} />
            <AccountsDropdownSubmenu accounts={LIABILITY} title={Account_Class.LIABILITY} isDebit={isDebit} saveDebitOrCredit={saveDebitOrCredit} />
            <RclDivider className="mb-0 mt-0"></RclDivider>
            <AccountsDropdownSubmenu accounts={ASSET} title={Account_Class.ASSET} isDebit={isDebit} saveDebitOrCredit={saveDebitOrCredit} />
            <AccountsDropdownSubmenu accounts={EQUITY} title={Account_Class.EQUITY} isDebit={isDebit} saveDebitOrCredit={saveDebitOrCredit} />
            <AccountsDropdownSubmenu accounts={REVENUE} title={Account_Class.REVENUE} isDebit={isDebit} saveDebitOrCredit={saveDebitOrCredit} />
            <AccountsDropdownSubmenu accounts={OTHER} title={Account_Class.OTHER} isDebit={isDebit} saveDebitOrCredit={saveDebitOrCredit} />
          </RclMenu>
        }
      >
        <RclSelect
          onSearch={onSearch}
          onSelect={onSelect}
          allowClear={isDebit}
          onClear={clearDebit}
          disabled={saveButtonLoading}
          dropdownStyle={{ display: `${isSearching ? '' : 'none'}` }}
          placeholder={'- Select account -'}
          value={getNameAndCodeFromID(isDebit ? debitCredit.debit : debitCredit.credit)}
        >
          {isSearching && accountsToSearch.map((gl: IGeneralLedgerAccount) =>
            <RclOption key={gl.id} value={JSON.stringify(gl)}>
              {renderGLAccount(gl, currentIntegration!.value)}
            </RclOption>)
          }
        </RclSelect>
      </RclDropdown>
    </RclFormItem>
  )
}

const AccountsDropdownSubmenu = ({ accounts, title, isDebit, saveDebitOrCredit, ...props }: { accounts: IGeneralLedgerAccount[], title: string, isDebit: boolean, saveDebitOrCredit: Function }) => {
  const currentIntegration = useAppSelector(selectCurrentIntegrationData)
  const renderSubtitle = useCallback((glAccount:IGeneralLedgerAccount) => {
    if (currentIntegration?.value === 'xero') {
      return glAccount.gl_code
    } else {
      if (glAccount.acct_num) {
        return glAccount.acct_num
      } else {
        return ''
      }
    }
  }, [currentIntegration])
  return (
    <RclSubMenu
      title={title}
      key={title}
      {...{ ...props, eventKey: title, popupClassName: 'accountsContainer' }}
    >
      {accounts?.map((singleAccount: IGeneralLedgerAccount) => {
        return <RclMenuItem
          {...{ ...props, eventKey: singleAccount.gl_code }}
          key={singleAccount.gl_code}>
          <RclRow
            onClick={() => saveDebitOrCredit(singleAccount.id)}
          >
            <RclText className="account-info-wrapper">
              <span>{singleAccount.name}</span>
              <span className="subtitle">{renderSubtitle(singleAccount)}</span>
            </RclText>
          </RclRow>
        </RclMenuItem>
      })}
    </RclSubMenu>
  )
}
