import { useEffect, useMemo } from "react";
import { selectJwt, setJwt } from "../reducers/userSlice";
import jeApi from "../services/jeApi";
import { useAppDispatch, useAppSelector } from "./reduxHooks";
import useApi from "./useApi";

const useJeApi = () => {
  const je_token = useAppSelector(selectJwt)
  const dispatch = useAppDispatch()
  const { api, ...methods } = useApi(jeApi)

  const authenticated = useMemo(() => !!je_token, [je_token])
  useEffect(() => {
    jeApi.interceptors.request.use(function (config) {
      return config;
    }, function (error) {
      return Promise.reject(error);
    });

    jeApi.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if (error?.response?.data?.errors?.length && error.response.data.errors[0] === 'No user is logged in.') {
        localStorage.removeItem('je_token');
        dispatch(setJwt(''))
      }
      return Promise.reject(error);
    });
  }, [dispatch])

  useEffect(() => {
    jeApi.defaults.headers.common['Authorization'] = !!je_token ? `Bearer ${je_token}` : undefined
  }, [je_token])

  return { authenticated, ...methods }
}

export default useJeApi
