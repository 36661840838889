import { AxiosInstance, AxiosRequestConfig } from "axios";
import { useCallback } from "react";


const useApi = (api: AxiosInstance) => {
  const get = useCallback(async <IType>(path: string, config: AxiosRequestConfig = {}) => {
    const response = await api.get<IType>(path, config)
    return response.data
  }, [api])

  const post = useCallback(async <IType>(path: string, data: any, config: AxiosRequestConfig = {}) => {
    const response = await api.post<IType>(path, data, config)
    return response.data
  }, [api])

  const patch = useCallback(async <IType>(path: string, data: any, config: AxiosRequestConfig = {}) => {
    const response = await api.patch<IType>(path, data, config)
    return response.data
  }, [api])

  const put = useCallback(async <IType>(path: string, data: any, config: AxiosRequestConfig = {}) => {
    const response = await api.put<IType>(path, data, config)
    return response.data
  }, [api])

  const remove = useCallback(async <IType>(path: string, config: AxiosRequestConfig = {}) => {
    const response = await api.delete<IType>(path, config)
    return response.data
  }, [api])

  return { get, post, patch, put, remove, api }
}

export default useApi
