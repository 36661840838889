import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface headerState {
  dashboardHeaderIsOnTop?: boolean;
}

const initialState: headerState = {
  dashboardHeaderIsOnTop: false,
};

export const globalModals = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setDashboardHeaderIsOnTop: (state, action: PayloadAction<boolean>) => {
      state.dashboardHeaderIsOnTop = action.payload;
    },
    reset: (state) => {
      state = initialState;
    }
  }
});

export const { setDashboardHeaderIsOnTop, reset } = globalModals.actions;

export const selectDashboardHeaderIsOnTop = (state: RootState) => state.header.dashboardHeaderIsOnTop;

export default globalModals.reducer;
