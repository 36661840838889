
import { Navigate, useNavigate } from 'react-router-dom';
import { RclCol, RclRow, RclTitle } from '@risepeopleinc/rcl-react';
import { useAppSelector } from '../../hooks/reduxHooks';
import { selectCurrentOrg, selectOrgIsConfigured, selectCurrentIntegrationData } from '../../reducers/orgSlice';
import './Dashboard.scss';
import RunReportModal from './components/RunReportModal/RunReportModal';
import JournalEntriesList from './components/JournalEntriesList/JournalEntriesList';
import WarningSection from './components/WarningSection/WarningSection';
import { DashboardHeader } from './components/DashboardHeader/DashboardHeader';
import JournalEntryForm, { JournalEntryFormSkeleton } from './components/JournalEntryForm/JournalEntryForm';
import { JEFormContextProvider } from '../../hooks/useJEFormContext';
import settingsIcon from '../../assets/images/settings.svg'
import syncSpinner from "../../assets/images/sync-spinner.svg";
import { selectJournalEntriesLoading } from '../../reducers/journalEntrySlice';
import { ToastContainer } from 'react-toastify';
import { useEffect } from 'react';
import { useSynchronize } from '../../hooks/useSynchronize';

const Dashboard = () => {
  const navigate = useNavigate()
  const currentOrg = useAppSelector(selectCurrentOrg)
  const currentIntegration = useAppSelector(selectCurrentIntegrationData)
  const orgIsConfigured = useAppSelector(selectOrgIsConfigured)
  const journalEntryLoading = useAppSelector(selectJournalEntriesLoading)
  const { synchronizeOrg, isCurrentOrgSyncing } = useSynchronize()

  useEffect(() => {
    if (!currentIntegration) return
    if (!currentOrg) return
    if (currentOrg.synchronizing) return
    if (!currentOrg.synchronized) synchronizeOrg(currentOrg.id)
  }, [currentOrg, currentIntegration, synchronizeOrg])

  if (!currentIntegration) {
    return <Navigate to="/integration-connect" />
  }

  if (!orgIsConfigured) {
    return <Navigate to="/journal-entry-settings" />
  }

  return (
    <div className='view-content'>
      <RclRow className="title-wrapper" justify="space-between">
        <RclCol>
          <RclTitle>Journal Entry</RclTitle>
        </RclCol>
        <div className="run-report-and-settings-wrapper">
          {isCurrentOrgSyncing && <div className='dashboard-sync-wrapper'>
            <img src={syncSpinner} alt="sync-spinner" className='dashboard-sync-spinner' />
            <span>Syncing data</span>
          </div>}
          <RclCol>
            <RunReportModal />
          </RclCol>
          <img onClick={() => navigate('/journal-entry-settings')} src={settingsIcon} className="settings-icon" alt='settings-icon' />
        </div>
      </RclRow>
      <RclRow>
        <JEFormContextProvider>
          <WarningSection />
        </JEFormContextProvider>
      </RclRow>
      <div>
        <DashboardHeader />
        <JournalEntriesList />
        <JEFormContextProvider>
          {journalEntryLoading ? <JournalEntryFormSkeleton /> : <JournalEntryForm />}
        </JEFormContextProvider>
      </div>
      <ToastContainer
        className={"toast-container"}
        position="top-left"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="dark"
      />
    </div>
  );
}

export default Dashboard
