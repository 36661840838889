import axios, { AxiosError } from "axios"
import { useCallback } from "react"
import useGlobalModals from "./useGlobalModals"

const defaultErrorMessage = 'Something went wrong. Please try again in a few seconds.'

const useErrorHandling = () => {
  const { setXeroReconnectOpenAction, setXeroErrorOpenAction } = useGlobalModals()
  const handleXeroError = useCallback((error: unknown | AxiosError) => {
    try {
      if (!axios.isAxiosError(error)) {
        setXeroErrorOpenAction({ message: (error as any).message, type: 'internal' })
        return;
      }

      let errorData = tryParseJson(error.response?.data)
      if (typeof errorData === 'string') {
        errorData = { errors: [defaultErrorMessage] }
      }
      const errorMessage = errorData.errors[0];

      if (['AuthenticationUnsuccessful', 'invalid_grant', 'Unauthorized'].includes(errorMessage)) {
        setXeroReconnectOpenAction(true)
      }
      else {
        setXeroErrorOpenAction({ message: errorMessage, type: errorData.type })
      }

    } catch (e) {
      setXeroErrorOpenAction({ message: defaultErrorMessage, type: 'internal' })
    }

  }, [setXeroReconnectOpenAction, setXeroErrorOpenAction])

  return { handleXeroError }
}

const tryParseJson = (jsonString: string) => {
  try {
    return JSON.parse(jsonString)
  }
  catch (e) {
    return jsonString
  }
}

export default useErrorHandling
