import {
  RclButton,
  RclForm,
  RclFormInstance,
  RclFormItem,
  RclInput,
  RclModal,
  RclRadio,
  RclRadioButton,
  RclRadioGroup,
  RclRangePicker
} from "@risepeopleinc/rcl-react"
import { AxiosRequestConfig } from "axios"
import { useCallback, useEffect, useRef, useState } from "react"
import { downloadFile, getCurrentDateUnderlined } from "../../../../helpers"
import { useAppSelector } from "../../../../hooks/reduxHooks"
import useErrorHandling from "../../../../hooks/useErrorHandling"
import useJeApi from "../../../../hooks/useJeApi"
import { selectCurrentIntegrationData, selectCurrentOrg } from "../../../../reducers/orgSlice"
import BalanceResultsModal from "../BalanceResultsModal/BalanceResultsModal"
import useCustomToast from '../../../../hooks/useCustomToast';
import './RunReportModal.scss'

export enum ReportType {
  PayRun = 'payrun',
  Dates = 'dates'
}

export enum TargetType {
  QBO = 'qbo-integration',
  Xero = 'xero-integration',
  CSV = 'csv',
  XLSX = 'xlsx',
  BALANCE = 'balance-checking'
}

enum ModalStatus {
  closed,
  first,
  second
}

const RunReportModal = () => {
  const { handleXeroError } = useErrorHandling()
  const currentIntegration = useAppSelector(selectCurrentIntegrationData)
  const [modalStatus, setModalStatus] = useState<ModalStatus>(ModalStatus.closed)
  const [payRunOrDate, setPayRunOrDate] = useState(ReportType.PayRun)
  const [downloadReportButton, setDownloadReportButton] = useState(false)
  const [balanceResult, setBalanceResult] = useState({ invoice_total: 0, mapping_total: 0 })
  const [loading, setLoading] = useState(false)
  const [isInvalidPayrun, setIsInvalidPayrun] = useState<boolean>(false)

  const currentOrg = useAppSelector(selectCurrentOrg)
  const { post } = useJeApi()
  const { showToastSuccess } = useCustomToast()

  const form = useRef<RclFormInstance>()

  useEffect(() => {
    form.current?.setFieldsValue({ payRunNumber: undefined, payRunYear: undefined, dateRange: undefined })
    setDownloadReportButton(false)
    setIsInvalidPayrun(false)
  }, [payRunOrDate])

  const onValuesChange = () => {
    if (!form.current) return
    const { payRunNumber, payRunYear, dateRange, targetType } = form.current.getFieldsValue()

    if (payRunOrDate === ReportType.PayRun && payRunNumber && payRunYear) {
      if (targetType) {
        setDownloadReportButton(true)
      }
    }
    if (payRunOrDate === ReportType.Dates && dateRange) {
      if (targetType) {
        setDownloadReportButton(true)
      }
    }
  }

  const onModalClose = useCallback((forceClose?: boolean) => {
    if (loading && !forceClose) return
    setModalStatus(ModalStatus.closed)
    setDownloadReportButton(false)
    setBalanceResult({ invoice_total: 0, mapping_total: 0 })
    setPayRunOrDate(ReportType.PayRun)
    setLoading(false)
    setIsInvalidPayrun(false)
    form.current?.resetFields()
  }, [loading])

  const submitReport = useCallback(async (overrideTargetType: TargetType | boolean = false) => {
    setIsInvalidPayrun(false)
    const { payRunNumber, payRunYear, dateRange, targetType } = form.current?.getFieldsValue()
    const target = overrideTargetType || targetType
    const isDownload = [TargetType.CSV, TargetType.XLSX].includes(target)
    const data: any = { format: target, report_by: payRunOrDate, use_je_item_description: true, check_for_invalid_payrun: true, save_manual_journal: true }
    if (payRunOrDate === ReportType.PayRun) {
      data['pay_run'] = payRunNumber
      data['year'] = payRunYear // "2020"
    } else if (payRunOrDate === ReportType.Dates) {
      data['start_date'] = dateRange[0].format('MM/DD/YYYY')
      data['end_date'] = dateRange[1].format('MM/DD/YYYY')
    }
    const isXLSX = target === TargetType.XLSX
    const config: AxiosRequestConfig = {}
    if (isDownload) config.responseType = isXLSX ? 'blob' : 'text'

    try {
      setLoading(true)
      const result = await post<any>(`/report?organization_id=${currentOrg?.id}`, data, config)
      if (result && result.invalid_payrun) {
        setIsInvalidPayrun(true)
        setLoading(false)
        return
      }

      setLoading(false)

      if (isDownload) {
        const blob = new Blob([result], { type: isXLSX ? 'application/octet-stream' : 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const currentDate = getCurrentDateUnderlined()
        downloadFile(url, isXLSX ? `JE_Report_${currentDate}.xlsx` : `JE_Report_${currentDate}.csv`)
      }

      if (target === TargetType.BALANCE) {
        console.log(result)
        const { invoice_total, mapping_total } = result
        setModalStatus(ModalStatus.second)
        setBalanceResult({ invoice_total, mapping_total })
      }

      if (target === TargetType.Xero) {
        onModalClose(true)
        showToastSuccess('Your journal entry was successfully sent to Xero')
      }
      if (target === TargetType.QBO) {
        onModalClose(true)
        showToastSuccess('Your journal entry was successfully sent to QuickBooks Online')
      }
    } catch (error) {
      onModalClose(true)
      handleXeroError(error)
    }
  }, [currentOrg, payRunOrDate, post, onModalClose, handleXeroError, showToastSuccess])

  return (
    <>
      <RclButton type='primary' onClick={() => setModalStatus(ModalStatus.first)}>Run Report</RclButton>
      <BalanceResultsModal
        visible={modalStatus === ModalStatus.second}
        balanceResult={balanceResult}
        onClose={onModalClose}
        submitReport={() => submitReport(currentIntegration?.targetType)}
        downloadReport={() => submitReport()}
        payRunOrDate={payRunOrDate}
        form={form}
      />
      <RclModal visible={modalStatus === ModalStatus.first} title="Run report" onCancel={() => onModalClose()}
        footer={[
          <RclButton key="back" onClick={() => onModalClose()} disabled={loading}>
            Cancel
          </RclButton>,
          <RclButton
            key="submit"
            type="default"
            loading={loading}
            disabled={!downloadReportButton}
            onClick={() => submitReport(TargetType.BALANCE)}>
            Run report
          </RclButton>
        ]}
      >
        <RclForm layout="vertical" ref={form} {...{ onValuesChange }}>
          <div className="run-report-modal-form-wrapper">
            <RclFormItem>
              <RclRadioGroup
                layout="horizontal"
                onChange={e => setPayRunOrDate(e.target.value)}
                disabled={loading}
                value={payRunOrDate}
                optionType="button"
                buttonStyle="solid"
                className="run-report-modal-radio-group"
              >
                <RclRadioButton value={ReportType.PayRun}>Run by pay run</RclRadioButton>
                <RclRadioButton value={ReportType.Dates}>Run by date</RclRadioButton>
              </RclRadioGroup>
            </RclFormItem>
            {payRunOrDate === ReportType.PayRun ?
              (
                <>
                  <RclFormItem className="mb-0" label='Pay run number' name='payRunNumber'>
                    <RclInput className={isInvalidPayrun ? "error-border" : ""} type='text' />
                  </RclFormItem>
                  {isInvalidPayrun && <p className="invalid-payrun-error-text mb-0">Payrun not found</p>}
                  <RclFormItem label='Pay run year' name='payRunYear'>
                    <RclInput type='number' />
                  </RclFormItem>
                </>
              ) :
              (
                <>
                  <RclFormItem className="mb-0" label='Date range' name='dateRange'>
                    <RclRangePicker
                      {...{ className: `${isInvalidPayrun ? "error-run-report-modal-date-picker" : "run-report-modal-date-picker"}` } as any}
                      disabled={loading}
                      format="MM/DD/YYYY"
                    />
                  </RclFormItem>
                  {isInvalidPayrun && <p className="invalid-payrun-error-text mb-0">Payrun not found</p>}
                </>
              )
            }
            <RclFormItem label='Downloadable report format' name='targetType'>
              <RclRadioGroup layout="horizontal" disabled={loading}>
                <RclRadio value={TargetType.XLSX}>Excel</RclRadio>
                <RclRadio value={TargetType.CSV}>CSV</RclRadio>
              </RclRadioGroup>
            </RclFormItem>
          </div>
        </RclForm>
      </RclModal>
    </>
  )
}

export default RunReportModal
