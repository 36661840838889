import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import useJeApi from "../hooks/useJeApi"

const FromXero = () => {
  const [params] = useSearchParams()
  const navigate = useNavigate()
  const { get } = useJeApi()

  useEffect(() => {
    (async () => {
      const tenantId = params.get('tenantId')
      const { exists: tenantExists } = await get<{ exists: boolean }>(`/xero_check_tenant`, { params: { tenant_id: tenantId } })
      if (tenantExists) {
        navigate('/login')
      } else {
        const result = await get<{ url: string }>('/xero_authorization_url', { params: { is_signup: true } })
        const { url } = result
        window.location.href = url
      }
    })()
  })
  return null
}

export default FromXero
