import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setAuthorizationHeader } from '../services/jeApi';
import { RootState } from '../store';

export interface userState {
  jwt?: string;
}

const initialState: userState = {
  jwt: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setJwt: (state, action: PayloadAction<string>) => {
      setAuthorizationHeader(action.payload);
      state.jwt = action.payload;
    },
    reset: (state) => {
      state = initialState;
    }
  }
});

export const { setJwt, reset } = userSlice.actions;

export const selectJwt = (state: RootState) => state.user.jwt;
export const selectJwtDecoded = (state: RootState) => {
  if (state.user.jwt) {
    return JSON.parse(atob(state.user.jwt.split('.')[1]));
  }
  return null;
}
export default userSlice.reducer;
