import { useMemo } from 'react';
import { RclButton, RclRow, RclSpace, RclText, RclIcon } from '@risepeopleinc/rcl-react'
import { useJEFormContext } from '../../../../hooks/useJEFormContext';
import { isMappingComplete } from '../../../../helpers';
import "./OverrideMappingHeader.scss"
import { OVERRIDE_TABS } from '../../../../interfaces/IJournalEntry';

interface IProps {
  selectedTab: string | undefined,
  setSelectedTab: React.Dispatch<React.SetStateAction<string | undefined>>,
  hasDepartmentOverride: boolean,
  hasEmployeeOverride: boolean,
}

export const OverrideMappingHeader: React.FC<IProps> = ({ selectedTab, setSelectedTab, hasDepartmentOverride, hasEmployeeOverride }) => {
  const { state } = useJEFormContext()

  const hasMainMapping = useMemo(() => {
    return isMappingComplete(state?.main)
  }, [state?.main])

  const changeTab = (whichTab: string) => {
    setSelectedTab(whichTab)
  }

  const style = useMemo(() => {
    if (selectedTab) return { paddingLeft: '24px' }
    return { padding: '0px 0px 24px 24px' }
  }, [selectedTab])

  return (
    <RclRow style={style}>
      <div className="need-specific-mappings-header">
        {(!hasDepartmentOverride)
          ? (selectedTab === OVERRIDE_TABS.department)
            ? <RclSpace className={`${(selectedTab === 'department') ? 'need-specific-mappings-tabs-header' : 'not-selected'}`}><RclText onClick={() => changeTab('department')} className='strong secondary'>Departments</RclText></RclSpace>
            : <RclButton disabled={!hasMainMapping} type='ghost' icon={<RclIcon type='content' iconName='Add' />} onClick={() => changeTab('department')}>Add Department</RclButton>
          : <RclSpace className={`${(selectedTab === 'department') ? 'need-specific-mappings-tabs-header' : 'not-selected'}`}><RclText onClick={() => changeTab('department')} className='strong secondary'>Departments</RclText></RclSpace>}
      </div>

      <div className="need-specific-mappings-header">
        {!(hasEmployeeOverride)
          ? (selectedTab === OVERRIDE_TABS.employee)
            ? <RclSpace className={`${(selectedTab === 'employee') ? 'need-specific-mappings-tabs-header' : 'not-selected'}`}><RclText onClick={() => changeTab('employee')} className='strong secondary'>Employees</RclText></RclSpace>
            : <RclButton disabled={!hasMainMapping} type='ghost' icon={<RclIcon type='content' iconName='Add' />} onClick={() => changeTab('employee')}>Add Employees</RclButton>
          : <RclSpace className={`${(selectedTab === 'employee') ? 'need-specific-mappings-tabs-header' : 'not-selected'}`}><RclText onClick={() => changeTab('employee')} className='strong secondary'>Employees</RclText></RclSpace>}
      </div>
    </RclRow>
  )
}
