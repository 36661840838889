import './Xero.scss';

interface XeroButtonProps {
  onClick: () => void;
}

export const XeroButton = ({ onClick } :XeroButtonProps) => {
  return (
    <button className='xero-button' onClick={onClick}>
      Connect to Xero
    </button>
  )
}