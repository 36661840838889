import { isRouteErrorResponse, useRouteError } from "react-router-dom";

const GenericError = () => {
  let error = useRouteError();
  console.error(error);
  if (isRouteErrorResponse(error)) {
    console.log(error.data)
  }
  return (
    <div>
      <h1>Something went wrong. Please try refreshing the page</h1>
    </div>
  )
}

export default GenericError
