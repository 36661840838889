import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { buildUrlWithParams } from "../helpers"
import { environment } from '../environments/environment';
import useJeApi from "../hooks/useJeApi"

const SignUpRedirect = () => {
  const [params] = useSearchParams()
  const { get } = useJeApi()
  useEffect(() => {
    (async () => {
      const newUrlSearchParams = new URLSearchParams(params)
      newUrlSearchParams.append('is_signup', 'true')
      const result = await get<{ id_token: string }>(buildUrlWithParams('xero_exchange_code', newUrlSearchParams));
      window.location.href = `${environment.SIGN_UP_URL}?from_xero=true&id_token=${result.id_token}`
    })()
  }, [params, get])
  return null
}

export default SignUpRedirect
