import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RclForm, RclTitle, RclFormItem, RclInput, RclButton, RclFormInstance, RclSpace, RclText, RclAlert, RclDivider } from '@risepeopleinc/rcl-react'
import useApi from "../../hooks/useApi";
import jeApi, { setAuthorizationHeader } from "../../services/jeApi";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { setJwt } from "../../reducers/userSlice";
import './Login.scss';

const Login = () => {
  const form = useRef<RclFormInstance>();
  const dispatch = useAppDispatch()
  const { get, post } = useApi(jeApi);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<{ message: string }[]>([])
  const closeRef = useRef<any>(null)

  const submit = async () => {
    setLoading(true)
    try {
      const result = await post<{ jwt: string }>('/login', form.current?.getFieldsValue())
      if (result) {
        setAuthorizationHeader(result.jwt)
        dispatch(setJwt(result.jwt))
        setLoading(false)
        navigate('/dashboard')
      }
    } catch (error) {
      setErrors([{ message: "Incorrect credentials" }])
      setLoading(false)
    }
  }

  const signUp = async () => {
    const result = await get<{ url: string }>('/xero_authorization_url', { params: { is_signup: true } })
    const { url } = result
    window.location.href = url
  }

  useEffect(() => {
    clearTimeout(closeRef.current)
    if (errors.length) {
      closeRef.current = setTimeout(() => setErrors([]), 5000)
    }
  }, [errors])

  return (
    <RclSpace className="login-container">
      <RclTitle>Rise journal entry solution</RclTitle>
      <RclText className="login-subtitle">Sign in using your Rise payroll credentials</RclText>
      <RclForm ref={form} layout="vertical">
        <RclFormItem name="username" label="Email*" className="item-list__custom">
          <RclInput name="username" placeholder="Email" onKeyDown={(e) => {
            if (e.key === 'Enter') submit();
          }} />
        </RclFormItem>
        <RclFormItem name="password" label="Password*" className="item-list__custom">
          <RclInput type="password" name="password" placeholder="Password" onKeyDown={(e) => {
            if (e.key === 'Enter') submit();
          }} />
        </RclFormItem>
        {errors.map(item =>
          <RclAlert
            message={item.message}
            icon
            type="error"
            closable
            afterClose={() => setErrors([])}
          />
        )}
        <RclButton type='primary' loading={loading} onClick={submit} block className="login-submit-button">Sign in</RclButton>
        <RclDivider />
        <div className="login-new-to-rise">
          <RclText strong>New to Rise?</RclText>
          <RclButton size='small' block  onClick={() => window.open("https://discover.risepeople.com/rise-qbo-integration/", "_blank")} className="login-new-to-rise-button" >Integrate with QuickBooks Online</RclButton>
          <RclButton size='small' block  onClick={signUp} className="login-new-to-rise-button" >Integrate with Xero Online</RclButton>
        </div>
      </RclForm>
    </RclSpace>
  );
}

export default Login
