import { RclSpace, RclText, RclTitle } from "@risepeopleinc/rcl-react";
import SorrySeeYouGoImage from "../../assets/images/sorry-see-you-go.svg";

import "./SorrySeeYouGo.scss";
import Box from "ui-box/dist/src/box";

export const SorrySeeYouGo = () => {
  return (
    <RclSpace className="ssyg-container">
      <Box maxWidth={769} display='flex' flexDirection='column' gap={8}>
        <RclTitle className="ssyg-title">You have successfully disconnected from QuickBooks Online.</RclTitle>
        <RclText className="ssyg-thanks">
        Thank you for using our journal entry integration. We hope it helped streamline your workflow. If you have any feedback or encountered any issues, please send us an email to <a className="ssyg-link" href="mailto:support@risepeople.com">support@risepeople.com</a>.
        </RclText>
      </Box>
      <Box display='flex' alignItems='flex-start' gap={106}>
        <Box flex={1} marginTop={60} maxWidth={456}>
        <RclText className="ssyg-feedback">
            How set up the Rise journal entry solution and connect with QuickBooks Online:
          </RclText>
          <Box display='flex' flexDirection='column' gap={4} marginTop={16}>
            <RclText>1. Use your Rise Payroll username and password to sign in</RclText>
            <RclText>2. Select QuickBooks Online as your accounting software </RclText>
            <RclText>3. Select your QuickBooks Online company</RclText>
            <RclText>4. Start creating your account mappings</RclText>
          </Box>
          <Box marginTop={16}>
          <RclText>To view our detailed guide to set up and connect Rise's journal entry solution to QuickBooks Online, <a rel="noreferrer" target="_blank" href="https://support.risepeople.com/portal/en/kb/articles/set-up-the-journal-entry-solution-and-connect-with-quickbooks" className="ssyg-link">click here.</a></RclText>
          </Box>
        </Box>
        <img
          src={SorrySeeYouGoImage}
          alt="Sorry to see you go"
          className="ssyg-image"
        />
      </Box>
    </RclSpace>
  );
};
