import { IDefaultDebitCredit, IGeneralLedgerAccount, IJournalEntry, IMapping } from "./interfaces/IJournalEntry";
import { IQboOrgConnection, IXeroOrgConnection } from "./interfaces/IOrganization";

const buildUrlWithParams = (url: string, params: URLSearchParams) => {
  const encoded = encodeParams(params);
  return `${url}?${encoded}`;
}

const encodeParams = (params: URLSearchParams) => {
  return Object.entries(Object.fromEntries(params)).map(([key, value]) => {
    return [key, value].map(encodeURIComponent).join("=");
  }).join("&");
}

const downloadFile = (url: string, fileName: string) => {
  let a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

const addMappingsToJE = (je: IJournalEntry, mappings: IMapping[]) => {
  const filtered = mappings.filter((mapping) => mapping.journal_entry_item_id === je.id)
  return { ...je, mappings: filtered }
}

const checkIfJEHasInactiveAccounts = (journalEntries: IJournalEntry[], inactiveAccounts: IGeneralLedgerAccount[]) => {
  return journalEntries.map(je => {
    const mappings = je.mappings
    let account_ids: number[] = []
    if (mappings?.length) {
      mappings.forEach(mapping => {
        if (mapping.credit_account) account_ids.push(mapping.credit_account_id)
        mapping.allocations.forEach(allocation => account_ids.push(allocation.debit_account_id))
      })
    }
    const inactive = inactiveAccounts.filter(account => account_ids.includes(account.id))
    return { id: je.id, inactive }
  }).filter(je => je.inactive.length > 0)
}

const getInactiveGLAccountsUsed = (journalEntries: IJournalEntry[], inactiveAccounts: IGeneralLedgerAccount[], generalLedgerAccounts: IGeneralLedgerAccount[]) => {
  const inactiveAccIds = inactiveAccounts.map((acc: IGeneralLedgerAccount) => acc.id)
  const res = inactiveAccIds.map(id => {
    let listOfMappings:any = []
    journalEntries.forEach(je => {
      if (je.mappings?.length){
        je.mappings.forEach(mapping => {
          if (mapping.credit_account_id === id) listOfMappings.push({...mapping, isDebit: false})
          mapping.allocations.forEach(allocation => {
            const already_exists = listOfMappings.some((x:any) => {
              return x.id === mapping.id
            })
            if (allocation.debit_account_id === id && !already_exists) {
              listOfMappings.push({...mapping, isDebit: true})
            }
          })
        })
      }
    })
    return {gl_account_id:id, listOfMappings}
  }).filter(x => x.listOfMappings.length > 0)
  return res
}

const getJEFromId = (id: number, journalEntries: IJournalEntry[]) => {
  if (!id) return undefined
  return journalEntries.find(x => x.id === id)
}

const getMainMapping = (mappings?: IMapping[]) => {
  if (!mappings) return undefined
  return mappings.find(mapping => mapping.department_id === null && mapping.employee_id === null)
}

const getOverrideCount = ( whichOverride: string, mappings?: IMapping[],) => {
  if (!mappings) return 0
  if (whichOverride === 'department') return mappings.filter(mapping => mapping.department_id).length
  if (whichOverride === 'employee') return mappings.filter(mapping => mapping.employee_id).length
  return 0
}

const isMappingComplete = (mapping: IDefaultDebitCredit) => {
  return !!(mapping?.debit && mapping?.credit)
}

const mappingToDefaultDebitCredit = (mapping?: IMapping, defaultCredit?: number): IDefaultDebitCredit => {
  if (!mapping) return { credit: defaultCredit, debit: undefined }
  return { credit: mapping.credit_account_id, debit: mapping.allocations[0]?.debit_account_id }
}

const getCurrentDateUnderlined = () => {
  return new Date().toISOString().replaceAll(/-|T|:|\./g, '_').replace('Z', '')
}

const getJEDefaultOrder = () => ['Earning', 'Hourly Earning', 'Salary Earning', 'Employer Contribution', 'Invoice Charge']

const isXeroOrgConnection = (orgConnection:IXeroOrgConnection | IQboOrgConnection): orgConnection is IXeroOrgConnection => {
  return 'xero_connection_id' in orgConnection
}

const isQboOrgConnection = (orgConnection:IXeroOrgConnection | IQboOrgConnection): orgConnection is IQboOrgConnection => {
  return 'qbo_company_id' in orgConnection
}

const renderGLAccount = (glAccount?: IGeneralLedgerAccount, currentIntegration?: string) => {
  if (!glAccount || !currentIntegration) return '-';
  if (currentIntegration === 'xero') {
    return `${glAccount.name} - ${glAccount.gl_code}`
  } else {
    if (glAccount.acct_num) {
      return `${glAccount.name} - ${glAccount.acct_num}`
    } else {
      return `${glAccount.name}`
    }
  }
}

export {
  getJEDefaultOrder,
  getCurrentDateUnderlined,
  buildUrlWithParams,
  downloadFile,
  addMappingsToJE,
  checkIfJEHasInactiveAccounts,
  getMainMapping,
  isMappingComplete,
  mappingToDefaultDebitCredit,
  getOverrideCount,
  getInactiveGLAccountsUsed,
  getJEFromId,
  isXeroOrgConnection,
  isQboOrgConnection,
  renderGLAccount
}
