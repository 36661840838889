import { RclCol, RclRow } from "@risepeopleinc/rcl-react"
import Skeleton from 'react-loading-skeleton'
import { IJournalEntry } from '../../../../interfaces/IJournalEntry'
import linkIcon from "../../../../assets/images/link.svg";
import overrideIcon from "../../../../assets/images/override-icon.svg"
import overrideEmployeeIcon from "../../../../assets/images/override-employee-icon.svg"
import "../../Dashboard.scss"
import "./SingleJournalEntry.scss"
import { selectCurrentJournalEntry, selectGeneralLedgerAccounts, setCurrentJournalEntry } from "../../../../reducers/journalEntrySlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import { useMemo } from "react";
import { selectCurrentIntegrationData, selectPrimaryBankAccount } from "../../../../reducers/orgSlice";
import { getMainMapping, getOverrideCount, renderGLAccount } from "../../../../helpers";

const SingleJournalEntry = ({ entry }: { entry: IJournalEntry }) => {
  const dispatch = useAppDispatch()
  const selectedEntry = useAppSelector(selectCurrentJournalEntry)
  const accounts = useAppSelector(selectGeneralLedgerAccounts)
  const primaryBankAccount = useAppSelector(selectPrimaryBankAccount)
  const mainMapping = useMemo(() => getMainMapping(entry.mappings), [entry.mappings])
  const currentIntegration = useAppSelector(selectCurrentIntegrationData);
  const deptOverrideCount = useMemo(() => getOverrideCount('department', entry.mappings), [entry.mappings])
  const empOverrideCount = useMemo(() => getOverrideCount('employee', entry.mappings), [entry.mappings])
  const debitAccount = useMemo(() => {
    return accounts.find((account) => account.id === mainMapping?.allocations[0]?.debit_account_id)
  }, [mainMapping, accounts])
  const creditAccount = useMemo(() => mainMapping?.credit_account, [mainMapping])

  const showLinkedCredit = useMemo(() => {
    if (creditAccount?.id === primaryBankAccount) return false;
    return true;
  }, [creditAccount, primaryBankAccount])

  const handleSelect = (entry: IJournalEntry) => {
    dispatch(setCurrentJournalEntry(entry))
  }

  return (
    <div
      className={`single-journal-entry-wrapper ${selectedEntry?.description === entry.description ? 'selected' : ''}`}
      onClick={() => handleSelect(entry)}
    >
      <RclRow className="entry-info-box">
        <p className="mb-0 primary strong">{entry.description}</p>
        <p className="mb-0 small greyed">{entry.code}</p>
      </RclRow>
      {mainMapping ? (
        <>
          <RclRow className="linked-debit-credit-wrapper">
            <RclCol className="mr-8">
              <img src={linkIcon} alt="link-icon" />
            </RclCol>
            <RclCol>
              <RclRow>
                <p className="entry-debit-credit-text secondary strong">{renderGLAccount(debitAccount, currentIntegration?.value)}</p>
              </RclRow>
              {showLinkedCredit && <RclRow>
                <p style={{ color: "grey" }} className="mb-0 small greyed">{renderGLAccount(creditAccount, currentIntegration?.value)}</p>
              </RclRow>}
            </RclCol>
          </RclRow>
          <div className='override-list-wrapper'>
            {deptOverrideCount > 0 &&
              <RclRow className="override-wrapper">
                <img src={overrideIcon} alt="override-icon" />
                <p className="strong small greyed">{`${deptOverrideCount} ${deptOverrideCount === 1 ? "department override" : "department overrides"}`}</p>
              </RclRow>
            }
            {empOverrideCount > 0 &&
              <RclRow className="override-wrapper">
                <img src={overrideEmployeeIcon} alt="override-employee-icon" />
                <p className="strong small greyed">{`${empOverrideCount} ${empOverrideCount === 1 ? "employee override" : "employee overrides"}`}</p>
              </RclRow>
            }
          </div>
        </>
      ) : null}
    </div>
  )
}

export const SingleJournalEntrySkeleton = () => (
  <div className="single-journal-entry-wrapper" style={{margin: "0 1rem 0 1rem"}}>
    <RclRow className="entry-info-box">
      <Skeleton width='80%' height={20} className='mb-4' />
      <Skeleton width='55%' height={16} />
    </RclRow>
  </div>
)

export default SingleJournalEntry
