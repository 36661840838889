import {
  RclSpace,
  RclTitle,
  RclText,
} from "@risepeopleinc/rcl-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { buildUrlWithParams } from "../../../../helpers";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import useJeApi from "../../../../hooks/useJeApi";
import {
  IConnectionDiff,
  IOrganization,
  IQboOrgConnection,
  IXeroOrgConnection,
} from "../../../../interfaces/IOrganization";
import {
  removeBrokenOrgFromList,
  selectCurrentIntegrationData,
  selectCurrentOrg,
  selectIsFirstIntegration,
  setCurrentOrg,
  setOrgSelectError,
} from "../../../../reducers/orgSlice";
import DifferentConnectionModal from "../DifferentConnectionModal/DifferentConnectionModal";
import SelectOrg from "../SelectOrg/SelectOrg";
import "./IntegrationConnect.scss";
import { IntegrationCard } from "./IntegrationCard/IntegrationCard";
import Box from "ui-box";
import { ReactComponent as XeroLogo } from "../../../../assets/images/xero-logo.svg";
import { ReactComponent as QboLogo } from "../../../../assets/images/qbo-logo.svg";
import { FurtherConnectionCard } from "./FurtherConnectionCard/FurtherConnectionCard";
import { QuickBooksButton } from "../IntegrationButtons/QuickBooks/QuickBooks";
import { XeroButton } from "../IntegrationButtons/Xero/Xero";

const POPUP_HEIGHT = 700;
const POPUP_WIDTH = 600;

const IntegrationConnect = () => {
  const { authenticated, get, post } = useJeApi();
  const navigate = useNavigate();
  const currentIntegration = useAppSelector(selectCurrentIntegrationData);
  const isFirstIntegration = useAppSelector(selectIsFirstIntegration);
  const currentOrg = useAppSelector(selectCurrentOrg);
  const brokenOrgs = useAppSelector((state) => state.org.brokenOrgs);
  const [integrationToConnect, setIntegrationToConnect] = useState<
    "xero" | "qbo" | undefined
  >(undefined);
  const [furtherConnectionSelect, setFurtherConnectionSelect] = useState<
    string | undefined
  >('new-connection');
  const [furtherConnectionOrgs, setFurtherConnectionOrgs] =
    useState<ReturnType<typeof parseFurtherConnectionOrgs>>(undefined);
  const [selectedOrg, setSelectedOrg] = useState<IOrganization | undefined>(undefined);
  const dispatch = useAppDispatch();
  const [popup, setPopup] = useState<Window | null>(null);
  const [differentConnectionModal, setDifferentConnectionModal] =
    useState(false);
  const [differentConnectionModalValues, setDifferentConnectionModalValues] =
    useState<IConnectionDiff | undefined>(undefined);
  const [isConnecting, setIsConnecting] = useState(false);

  const disabledProceed = useMemo(() => {
    return !integrationToConnect 
    || (furtherConnectionSelect === undefined && !!furtherConnectionOrgs?.length)
    || (isFirstIntegration && !selectedOrg);
  }, [furtherConnectionOrgs?.length, furtherConnectionSelect, integrationToConnect, isFirstIntegration, selectedOrg])

  const getIntegrationUrl = useCallback(async () => {
    if (!currentOrg || disabledProceed) {
      dispatch(setOrgSelectError("This field is required."));
      return;
    };
    if (isConnecting) return;
    setIsConnecting(true);
    if (!["new-connection", undefined].includes(furtherConnectionSelect)) {
      const { furtherConnectionUrl, payload } = getFurtherConnectionUrl({
        integrationToConnect,
        currentOrg,
        furtherConnectionSelect,
      });
      const result = await post<IOrganization>(furtherConnectionUrl, payload);
      dispatch(setCurrentOrg(result));
      setIsConnecting(false);
    } else {
      const authorizationUrl = `${integrationToConnect}_authorization_url`;
      const { url } = await get<{ url: string }>(authorizationUrl);
      const top = window.outerHeight / 2 + window.screenY - POPUP_HEIGHT / 2;
      const left = window.outerWidth / 2 + window.screenX - POPUP_WIDTH / 2;
      setPopup(
        window.open(
          url,
          "Xero Connect",
          `width=${POPUP_WIDTH},height=${POPUP_HEIGHT},top=${top},left=${left}`
        )
      );
      return url;
    }
  }, [currentOrg, disabledProceed, isConnecting, furtherConnectionSelect, dispatch, integrationToConnect, post, get]);

  const exchangeCode = useCallback(
    async (searchParams: URLSearchParams) => {
      if (!popup || !currentOrg) return;
      await new Promise((resolve) => setTimeout(resolve, 1000));
      popup.close();
      setPopup(null);
      searchParams.append("organization_id", currentOrg.id.toString());
      const exchangeCode =
        integrationToConnect === "xero"
          ? "xero_exchange_code"
          : "qbo_exchange_code";
      const result = await get<IOrganization | IConnectionDiff>(
        buildUrlWithParams(exchangeCode, searchParams)
      );
      if ("connection_diff" in result) {
        setDifferentConnectionModalValues(result);
        setDifferentConnectionModal(true);
      } else {
        dispatch(setCurrentOrg(result));
        dispatch(removeBrokenOrgFromList(currentOrg.id));
      }
      setIsConnecting(false);
    },
    [popup, currentOrg, integrationToConnect, get, dispatch]
  );

  const selectIntegrationToConnect = useCallback(
    (value: "xero" | "qbo") => {
      setIntegrationToConnect(value);
      get(`/${value}_orgs_connected`).then((data) => {
        setFurtherConnectionOrgs(parseFurtherConnectionOrgs(data as any, brokenOrgs));
      });
    },
    [brokenOrgs, get]
  );

  useEffect(() => {
    if (!popup) return;
    const timer = setInterval(async () => {
      if (popup?.closed) {
        timer && clearInterval(timer);
        setPopup(null);
        setIsConnecting(false);
      }
      if (!popup) {
        timer && clearInterval(timer);
        return;
      }
      const currentUrl = popup.location.href;
      if (!currentUrl) {
        return;
      }
      const searchParams = new URL(currentUrl).searchParams;
      if (!!searchParams.get("code")) {
        timer && clearInterval(timer);
        exchangeCode(searchParams);
      }
    }, 250);
  }, [popup, exchangeCode]);

  useEffect(() => {
    if (currentIntegration && authenticated && !brokenOrgs.find((id) => id === currentOrg?.id)) {
      if (currentOrg?.default_credit_account_id) {
        navigate("/dashboard");
      } else {
        navigate("/integration-connect-step-2");
      }
    }
  }, [currentIntegration, authenticated, navigate, currentOrg?.default_credit_account_id, brokenOrgs.length, brokenOrgs, currentOrg?.id]);

  return (
    <>
      <DifferentConnectionModal
        open={differentConnectionModal}
        setOpen={setDifferentConnectionModal}
        diffValues={differentConnectionModalValues}
      />
      <RclSpace className="integration-connect-container" direction="vertical">
        <div className="integration-connect-wrapper">
          <RclTitle>
            {isFirstIntegration && 'Welcome! '}Let’s get you set up.
          </RclTitle>
          <RclTitle level={2}>1. Select your accounting software.</RclTitle>
          <div className="subtitle">
            <RclText>
              Connect an integration to unlock the potential of automated data entry. Import your chart of accounts and create journal entries in your general ledger without lifting a finger.
            </RclText>
          </div>
          <Box
            display="flex"
            gap={24}
            marginY={32}
            paddingLeft={24}
            flexGrow={1}
            width="100%"
          >
            <IntegrationCard
              value="qbo"
              title="QuickBooks Online"
              icon={QboLogo}
              url="https://quickbooks.intuit.com"
              onSelect={selectIntegrationToConnect}
              name="integration-radio-group"
            />
            <IntegrationCard
              value="xero"
              title="Xero"
              icon={XeroLogo}
              url="https://xero.com"
              onSelect={selectIntegrationToConnect}
              name="integration-radio-group"
            />
          </Box>
          {!!furtherConnectionOrgs?.length && (
            <Box
              background="#F2F5FB"
              marginBottom={32}
              marginLeft={24}
              padding={16}
              width="calc(100% - 24px)"
              borderRadius={8}
            >
              <RclText strong>Please select an option:</RclText>
              <Box display="flex" gap={24} flexWrap="wrap" marginTop={12}>
                <FurtherConnectionCard
                  name="further-connection-radio-group"
                  value="new-connection"
                  title={`Connect to a new ${
                    integrationToConnect === "qbo" ? "QuickBooks Online" : "Xero"
                  } organization`}
                  onSelect={setFurtherConnectionSelect}
                  checked={furtherConnectionSelect === "new-connection"}
                />
                {furtherConnectionOrgs?.map((org) => (
                  <FurtherConnectionCard
                    name="further-connection-radio-group"
                    value={org.id.toString()}
                    title={`Connect to ${org.orgName}`}
                    subtitle={`Connected to ${org.riseOrgs}`}
                    onSelect={setFurtherConnectionSelect}
                    checked={furtherConnectionSelect === org.id.toString()}
                  />
                ))}
              </Box>
            </Box>
          )}
          <RclTitle level={2}>
            2. Select your Rise payroll organization.
          </RclTitle>
          <div className="subtitle">
            <SelectOrg hideLabel={true} onSelect={setSelectedOrg} />
          </div>
          <Box alignSelf="flex-end" marginTop={32}>
            {integrationToConnect === "xero" && <XeroButton onClick={getIntegrationUrl} />}
            {integrationToConnect === "qbo" && <QuickBooksButton onClick={getIntegrationUrl} />}
            {/* <RclButton
              loading={isConnecting}
              onClick={getIntegrationUrl}
              type={"primary"}
              disabled={disabledProceed}
            >
              Proceed
              <ArrowRight fill={!disabledProceed ? "white" : "#aaadb0"} />
            </RclButton> */}
          </Box>
        </div>
      </RclSpace>
    </>
  );
};

interface parseFurtherConnectionOrgsProps {
  orgs_mapped: { [key: string]: IOrganization[] };
  qbo_org_connections: IQboOrgConnection[];
  xero_org_connections: IXeroOrgConnection[];
}

const parseFurtherConnectionOrgs = (data: parseFurtherConnectionOrgsProps, brokenOrgs: number[]) => {
  const { orgs_mapped, qbo_org_connections, xero_org_connections } = data;
  if (qbo_org_connections) {
    return qbo_org_connections
    .map((qbo_org) => ({
      id: qbo_org.id,
      orgName: qbo_org.qbo_company_name,
      riseOrgs: orgs_mapped[qbo_org.id.toString()]
        .filter(org => !brokenOrgs.includes(org.id))
        .map((org) => org.name)
        .join(", "),
    }))
    .filter(org => org.riseOrgs.length > 0);
  }
  if (xero_org_connections) {
    return xero_org_connections.map((xero_org) => ({
      id: xero_org.id,
      orgName: xero_org.xero_tenant_name,
      riseOrgs: orgs_mapped[xero_org.id.toString()]
        .filter(org => !brokenOrgs.includes(org.id))
        .map((org) => org.name)
        .join(", "),
    }))
    .filter(org => org.riseOrgs.length > 0);
  }
};
interface getFurtherConnectionUrlParams {
  integrationToConnect?: "xero" | "qbo";
  currentOrg: IOrganization;
  furtherConnectionSelect: string | undefined;
}
const getFurtherConnectionUrl = (params: getFurtherConnectionUrlParams) => {
  const { currentOrg, furtherConnectionSelect, integrationToConnect } = params;
  const furtherConnectionUrl =
    integrationToConnect === "xero"
      ? `xero_connect_existing_xero_org?organization_id=${currentOrg.id}`
      : `qbo_connect_existing_qbo_org?organization_id=${currentOrg.id}`;
  const payload =
    integrationToConnect === "xero"
      ? { xero_org_connection_id: furtherConnectionSelect }
      : { qbo_org_connection_id: furtherConnectionSelect };
  return { furtherConnectionUrl, payload };
};

export default IntegrationConnect;
