import { useCallback } from "react"
import { selectErrorMessage, selectErrorOpen, selectErrorType, selectXeroReconnectOpen, setErrorOpen, setXeroReconnectOpen } from "../reducers/globalModalsSlice"
import { useAppDispatch, useAppSelector } from "./reduxHooks"

const useGlobalModals = () => {
  const dispatch = useAppDispatch()
  const xeroReconnectOpen = useAppSelector(selectXeroReconnectOpen)
  const errorMessage = useAppSelector(selectErrorMessage)
  const errorType = useAppSelector(selectErrorType)
  const errorOpen = useAppSelector(selectErrorOpen)

  const setXeroReconnectOpenAction = useCallback((open: boolean): void => {
    dispatch(setXeroReconnectOpen(open))
  }, [dispatch])

  const setXeroErrorOpenAction = useCallback((data: { message: string, type: string } | false): void => {
    dispatch(setErrorOpen(data))
  }, [dispatch])

  return {
    xeroReconnectOpen, setXeroReconnectOpenAction,
    errorOpen, setXeroErrorOpenAction, errorMessage, errorType
  }
}

export default useGlobalModals;
