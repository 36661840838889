import deepEqual from 'deep-equal';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { getMainMapping, isMappingComplete, mappingToDefaultDebitCredit } from '../helpers';
import { IDefaultDebitCredit } from '../interfaces/IJournalEntry';
import { resetMappings, selectCurrentJournalEntry, selectDepartments, selectEmployees, setSaveMappingLoading } from '../reducers/journalEntrySlice';
import { selectCurrentOrg, selectPrimaryBankAccount } from '../reducers/orgSlice';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import useJeApi from './useJeApi';

interface IJEFormContext {
  state: { [key: string]: IDefaultDebitCredit },
  initialState: { [key: string]: IDefaultDebitCredit },
  setStateForKey: (key: string, value: IDefaultDebitCredit) => void,
  setInitialStateForKey: (key: string, value: IDefaultDebitCredit) => void,
  setState: React.Dispatch<React.SetStateAction<any>>,
  setInitialState: React.Dispatch<React.SetStateAction<any>>,
}

const JEFormContext = createContext<IJEFormContext>({
  state: {},
  initialState: {},
  setStateForKey: () => { },
  setInitialStateForKey: () => { },
  setState: () => { },
  setInitialState: () => { },
})
export const JEFormContextProvider: React.FC<any> = ({ children }) => {
  const currentOrg = useAppSelector(selectCurrentOrg)
  const selectedEntry = useAppSelector(selectCurrentJournalEntry)
  const departments = useAppSelector(selectDepartments)
  const employees = useAppSelector(selectEmployees)
  const primaryBankAccount = useAppSelector(selectPrimaryBankAccount)
  const dispatch = useAppDispatch()
  const { put, get } = useJeApi()

  const [state, setState] = useState<{ [key: string]: IDefaultDebitCredit }>({ main: {} });
  const [initialState, setInitialState] = useState<{ [key: string]: IDefaultDebitCredit }>({ main: {} });

  const setStateForKey = useCallback((key: string, value: any) => {
    setState(s => ({ ...s, [key]: value }))
  }, [])

  const setInitialStateForKey = useCallback((key: string, value: any) => {
    setInitialState(s => ({ ...s, [key]: value }))
  }, [])

  const saveMapping = useCallback((async (newState: { [key: string]: IDefaultDebitCredit }) => {
    dispatch(setSaveMappingLoading(true))
    let stateAsEntry = Object.entries(newState).filter(([_k, v]) => isMappingComplete(v))

    // if main is not set, but there are other mappings, alert about cascade deleting
    if (!stateAsEntry.find(([k]) => k === 'main') && stateAsEntry.length) {
      const confirmation = window.confirm('This will delete all mappings for this Payroll Instruction. Are you sure you want to proceed?')
      if (confirmation) {
        stateAsEntry = []
      } else {
        dispatch(setSaveMappingLoading(false))
        return false
      }
    }
    const data = stateAsEntry
      .map(([k, v]) => {
        if (k === 'main') {
          const mapping = getMainMapping(selectedEntry?.mappings)
          return ({ id: mapping?.id, creditAccountId: v.credit, journalEntryItemId: selectedEntry?.id, allocations: [{ id: mapping?.allocations[0]?.id, percentage: 100, debitAccountId: v.debit }] })
        }
        if (k.includes('department')) {
          const departmentId = parseInt(k.replace("department", ''), 0)
          const mapping = selectedEntry?.mappings?.find((m => m.department_id === departmentId))
          return ({ id: mapping?.id, creditAccountId: v.credit, journalEntryItemId: selectedEntry?.id, departmentId, allocations: [{ id: mapping?.allocations[0]?.id, percentage: 100, debitAccountId: v.debit }] })
        }
        if (k.includes('employee')) {
          const employeeId = parseInt(k.replace("employee", ''), 0)
          const mapping = selectedEntry?.mappings?.find((m => m.employee_id === employeeId))
          return ({ id: mapping?.id, creditAccountId: v.credit, journalEntryItemId: selectedEntry?.id, employeeId, allocations: [{ id: mapping?.allocations[0]?.id, percentage: 100, debitAccountId: v.debit }] })
        }
        return {}
      })
    await put(`create_update_multiple_mappings?organization_id=${currentOrg?.id}&journal_entry_item_id=${selectedEntry?.id}`, data);
    await dispatch(resetMappings({ get }, currentOrg?.id));
    dispatch(setSaveMappingLoading(false))
    return true
  }), [selectedEntry, currentOrg, dispatch, put, get])

  // fill state with department keys
  useEffect(() => {
    setState(s => {
      const newState = { ...s }
      const mainMapping = getMainMapping(selectedEntry?.mappings)
      newState['main'] = mappingToDefaultDebitCredit(mainMapping, primaryBankAccount)
      departments.forEach(d => {
        const keyName = 'department' + d.id.toString()
        const mapping = selectedEntry?.mappings?.find((m => m.department_id === d.id))
        newState[keyName] = mappingToDefaultDebitCredit(mapping, primaryBankAccount)
      })
      employees.forEach(e => {
        const keyName = 'employee' + e.id.toString()
        const mapping = selectedEntry?.mappings?.find((m => m.employee_id === e.id))
        newState[keyName] = mappingToDefaultDebitCredit(mapping, primaryBankAccount)
      })
      setInitialState(newState)
      return newState
    })
  }, [employees, departments, selectedEntry, primaryBankAccount])

  // save mapping when something changes
  useEffect(() => {
    const canSave = !deepEqual(state, initialState)
    if (canSave) {
      const success = saveMapping(state)

      if (!success) setState(initialState)
      else setInitialState(state)
    }
  }, [initialState, saveMapping, state])

  return (
    <JEFormContext.Provider value={{
      state,
      initialState,
      setState,
      setInitialState,
      setStateForKey,
      setInitialStateForKey
    }}>
      {children}
    </JEFormContext.Provider>
  )
}

export const useJEFormContext = () => {
  const context = useContext(JEFormContext);
  return context;
}
