import { useMemo } from "react";
import KbArticleIcon from "../../../../assets/images/kb-article-icon.svg";
import './KBArticleLink.scss'

const KBArticleLink = ({ link, wording }: { link: string, wording?: string }) => {
  const finalWording = useMemo(() => wording || 'Learn more', [wording])
  return (
    <a href={link} target="_blank" className='kb-link-wrapper'>
      <span className='link'>{finalWording}</span>
      <img src={KbArticleIcon} alt='kb-article-icon' />
    </a>
  )
}

export default KBArticleLink
