import { useCallback, useMemo } from "react"
import { setAfterSync, setJournalEntriesLoading, selectGeneralLedgerAccounts } from "../reducers/journalEntrySlice"
import { setOrgSync, selectOrgs, selectCurrentOrg } from "../reducers/orgSlice"
import { useAppDispatch, useAppSelector } from "./reduxHooks"
import useErrorHandling from "./useErrorHandling"
import useJeApi from "./useJeApi"

export const useSynchronize = () => {
  const { handleXeroError } = useErrorHandling()
  const orgs = useAppSelector(selectOrgs)
  const currentOrg = useAppSelector(selectCurrentOrg)
  const generalLedgerAccounts = useAppSelector(selectGeneralLedgerAccounts)
  const dispatch = useAppDispatch()
  const { get, put } = useJeApi()

  const isCurrentOrgSyncing = useMemo(() => currentOrg?.synchronizing, [currentOrg?.synchronizing])

  const synchronizeOrg = useCallback(async (id: number) => {
    if (!orgs?.length) return
    if (!generalLedgerAccounts?.length) return
    const orgToSync = orgs.find((org) => org.id === id)
    console.log('syncing ', orgToSync?.name, orgToSync?.id)
    if (!orgToSync) return;
    dispatch(setOrgSync({ orgId: id, synchronized: false, synchronizing: true }))

    try {
      await put('/organization', { obj_id: orgToSync?.obj_id })

      const [JEItems, mappings, departments, employees] = await Promise.all([
        get<any>(`/journal-entry-items?organization_id=${orgToSync.id}`),
        get<any>(`/mappings?organization_id=${orgToSync.id}`),
        get<any>(`/departments?organization_id=${orgToSync.id}`),
        get<any>(`/employees?organization_id=${orgToSync.id}`)
      ])

      dispatch(setOrgSync({ orgId: id, synchronized: true, synchronizing: false }))
      if (!JEItems?.length) return
      dispatch(setAfterSync({
        journalEntries: JEItems,
        mappings,
        departments,
        employees
      }))
      dispatch(setJournalEntriesLoading(false))
    } catch (error) {
      handleXeroError({ message: 'Something went wrong while synchronizing, please try refreshing the page' })
    }
  }, [get, orgs, put, dispatch, generalLedgerAccounts, handleXeroError])

  return { synchronizeOrg, isCurrentOrgSyncing }
}

