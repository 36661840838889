import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useAppDispatch } from './hooks/reduxHooks';
import { setJwt } from './reducers/userSlice';
import router from './Routes';
import './App.scss';
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const dispatch = useAppDispatch();
  const [checkedJwt, setCheckedJwt] = useState(false);
  useEffect(() => {
    console.log(process.env.REACT_APP_ENV)
  }, [])
  useEffect(() => {
    const je_token = localStorage.getItem('je_token');
    if (je_token) dispatch(setJwt(je_token));
    setCheckedJwt(true);
  }, [dispatch])

  if (!checkedJwt) return null
  return (<RouterProvider router={router} />)
}

export default App;
