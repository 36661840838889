import {
  RclButton,
  RclModal,
  RclSpace,
  RclText,
  RclTitle
} from "@risepeopleinc/rcl-react"
import { useState } from "react";
import XeroError from "../../../../assets/images/xero-error.svg";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import useErrorHandling from "../../../../hooks/useErrorHandling";
import useJeApi from "../../../../hooks/useJeApi";
import { IConnectionDiff, IOrganization } from "../../../../interfaces/IOrganization";
import { selectCurrentOrg, setCurrentOrg } from "../../../../reducers/orgSlice";
import './DifferentConnectionModal.scss'

interface IDifferentConnectionModal {
  open: boolean
  setOpen: (open: boolean) => void
  diffValues?: IConnectionDiff
}

const DifferentConnectionModal: React.FC<IDifferentConnectionModal> = ({ open, setOpen, diffValues }) => {
  const { handleXeroError } = useErrorHandling()
  const dispatch = useAppDispatch();
  const currentOrg = useAppSelector(selectCurrentOrg);
  const { post, remove } = useJeApi()
  const [loading, setLoading] = useState<{ cancel: boolean, proceed: boolean }>({ cancel: false, proceed: false })

  const proceed = async () => {
    try {
      setLoading({ ...loading, proceed: true })
      const result = await post<IOrganization>(`xero_connect_existing_xero_org?organization_id=${currentOrg?.id}`, { xero_org_connection_id: diffValues?.last_connection_id })
      setLoading({ ...loading, proceed: false })
      dispatch(setCurrentOrg(result));
    } catch (err) {
      handleXeroError(err)
    }
    closeModal()
  }

  const cancel = async () => {
    try {
      setLoading({ ...loading, cancel: true })
      await remove<null>(`xero_disconnect_existing_xero_org?organization_id=${currentOrg?.id}`, { params: { xero_org_connection_id: diffValues?.last_connection_id } })
      setLoading({ ...loading, cancel: false })
    } catch (err) {
      handleXeroError(err)
    }
    closeModal()
  }

  const closeModal = () => {
    setOpen(false)
    setLoading({ cancel: false, proceed: false })
  }

  return (
    <RclModal
      wrapClassName="different-connection-modal-wrapper"
      visible={open}
      closable={false}
      maskClosable={false}
      footer={
        <div className="different-connection-modal-button-wrapper">
          <RclButton key="back" onClick={cancel} disabled={loading.proceed} loading={loading.cancel}>
            Cancel
          </RclButton>
          <RclButton key="proceed" type='primary' onClick={proceed} disabled={loading.cancel} loading={loading.proceed}>
            Connect and erase mappings
          </RclButton>
        </div>
      }>
      <RclSpace direction='vertical' className="different-connection-modal-body-wrapper">
        <img src={XeroError} alt='xero-error' />
        <RclTitle level={2}>Any previous mappings will be erased.</RclTitle>
        <RclText className="different-connection-modal-error-message">
          Connecting to <b>{diffValues?.last_connection_name}</b> will erase any mappings you created from your previous connection to <b>{diffValues?.previous_connection_name}</b>. Please confirm if you would like to continue.
        </RclText>
      </RclSpace>
    </RclModal>
  )
}

export default DifferentConnectionModal
