import { environment as pftest } from './environment.pftest'
import { environment as staging } from './environment.staging'
import { environment as production } from './environment.production'

const development = {
  name: 'development',
  production: false,
  JE_API_URL: 'http://localhost:3000',
  SIGN_UP_URL: 'https://discover.risepeople.com/rise-wealth-opt-in-1/',
  // JE_API_URL: 'https://je-test.risepeople.com/',
  // JE_API_URL: 'https://je-gateway.rise-staging.com',
  APP_NAME: 'je-utility-fe'
};

export const environment = (() => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'pftest': return pftest;
    case 'staging': return staging;
    case 'production': return production;
    default: return development;
  }
})()

