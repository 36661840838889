import { RclSelect, RclText, RclTooltip } from "@risepeopleinc/rcl-react";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../../hooks/reduxHooks";
import { useSynchronize } from "../../../../hooks/useSynchronize";
import { setGeneralLedgerAccounts } from "../../../../reducers/journalEntrySlice";
import {
  selectCurrentOrg,
  setCurrentOrg,
  selectOrgs,
  selectIsFirstIntegration,
  selectOrgSelectError,
  setOrgSelectError,
} from "../../../../reducers/orgSlice";
import "./SelectOrg.scss";
import Box from "ui-box/dist/src/box";
import { IOrganization } from "../../../../interfaces/IOrganization";
import { ReactComponent as XeroLogo } from "../../../../assets/images/xero-logo.svg";
import { ReactComponent as QboLogo } from "../../../../assets/images/qbo-logo.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/images/plus-icon.svg";

const SelectOrg = ({
  hideLabel,
  headerMode,
  onSelect,
}: {
  hideLabel?: boolean;
  headerMode?: boolean;
  onSelect?: (org:IOrganization) => void;
}) => {
  const currentOrg = useAppSelector(selectCurrentOrg);
  const brokenOrgs = useAppSelector((state) => state.org.brokenOrgs);
  const orgSelectError = useAppSelector(selectOrgSelectError);
  const isFirstIntegration = useAppSelector(selectIsFirstIntegration);
  const allOrgs = useAppSelector(selectOrgs);
  const { isCurrentOrgSyncing } = useSynchronize();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const onSelectValue = (value: any) => {
    dispatch(setOrgSelectError(undefined));
    dispatch(setGeneralLedgerAccounts([]));
    dispatch(setCurrentOrg(JSON.parse(value)));
    if (onSelect) onSelect(JSON.parse(value));
  };

  const className = useMemo(() => {
    let result = "select-org-input";
    if (headerMode) result += " header-mode";
    else result += " default-mode";
    return result;
  }, [headerMode]);

  const shouldHide = useMemo(() => {
    if (location.pathname === "/sign-in-successful") return true;
    if (
      ["/integration-connect", "/integration-connect-step-2"].includes(
        location.pathname
      ) &&
      isFirstIntegration &&
      headerMode
    )
      return true;
    else return false;
  }, [isFirstIntegration, headerMode, location.pathname]);

  if (shouldHide) return null;
  return (
    <div className="select-rise-org-wrapper">
      {!hideLabel && <Box><RclText>Rise payroll organization:</RclText></Box>}
      <RclTooltip
        title={isCurrentOrgSyncing ? "Wait for synchronize..." : ""}
        overlay=""
      >
        <Box display='flex' flexDirection='column' flex={1}>
        <RclSelect
          bordered={false}
          defaultValue={headerMode ? currentOrg?.name : undefined}
          //@ts-ignore
          className={className}
          disabled={
            isCurrentOrgSyncing || (!isFirstIntegration && !headerMode)
          }
         
          onChange={onSelectValue}
          options={allOrgs.map((org) => ({
            value: JSON.stringify(org),
            label: (
              <Box display="flex" alignItems="center" gap={8}>
                {headerMode && getOrgIcon(org)}
                {org.name}
              </Box>
            ),
          }))}
          dropdownMatchSelectWidth={!headerMode}
          dropdownStyle={{minWidth: '350px'}}
          placeholder="Select organization"
          status={orgSelectError ? "error" : undefined}
          value={headerMode || brokenOrgs.includes(currentOrg?.id!) || !isFirstIntegration ? currentOrg?.name : undefined}
        ></RclSelect>
        {orgSelectError && <RclText className='select-rise-org-error-text'>{orgSelectError}</RclText>}
        </Box>
      </RclTooltip>
    </div>
  );
};

const getOrgIcon = (org: IOrganization) => {
  if (org.qbo_org_connections?.length > 0) {
    return <QboLogo width={24} height={24} />;
  }
  if (org.xero_org_connections?.length > 0) {
    return <XeroLogo width={24} height={24} />;
  }
  return <PlusIcon width={24} height={16} />;
};

export default SelectOrg;
