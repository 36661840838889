import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route } from 'react-router-dom'
import useJeApi from './hooks/useJeApi';
import JournalEntrySettings from './pages/Dashboard/components/JournalEntrySettings/JournalEntrySettings';
import IntegrationConnect from './pages/Dashboard/components/IntegrationConnect/IntegrationConnect';
import Dashboard from './pages/Dashboard/Dashboard';
import FromXero from './pages/FromXero';
import GenericError from './pages/GenericError';
import Login from './pages/Login/Login';
import Root from './pages/Root/Root';
import SignInSuccessful from './pages/SignInSuccessful'
import SignUpRedirect from './pages/SignUpRedirect';
import IntegrationConnectStep2 from './pages/Dashboard/components/IntegrationConnectStep2/IntegrationConnectStep2';
import { SorrySeeYouGo } from './pages/SorrySeeYouGo/SorrySeeYouGo';

interface ChildrenProps {
  children?: React.ReactNode;
}

const ProtectedRoute: React.FC<ChildrenProps> = (props) => {
  const { authenticated } = useJeApi();
  return authenticated ? <Outlet /> : <Navigate to="/login" />;
};

const PreLoginRoute: React.FC<ChildrenProps> = (props) => {
  const { authenticated } = useJeApi();
  return authenticated ? <Navigate to="/dashboard" /> : <Outlet />;
};

const router = createBrowserRouter(createRoutesFromElements(
  <Route path='/' element={<Root />} errorElement={<GenericError />}>
    <Route path="/" element={<ProtectedRoute />}>
      <Route path="/" element={<Dashboard />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/journal-entry-settings" element={<JournalEntrySettings />} />
      <Route path="/sign-in-successful" element={<SignInSuccessful />} />
      <Route path="/integration-connect" element={<IntegrationConnect />} />
      <Route path="/integration-connect-step-2" element={<IntegrationConnectStep2 />} />
    </Route>
    <Route path="/" element={<PreLoginRoute />}>
      <Route path="/login" element={<Login />} />
      <Route path="/sign-up-redirect" element={<SignUpRedirect />} />
      <Route path="/from-xero" element={<FromXero />} />
    </Route>
    <Route path="/feedback" element={<SorrySeeYouGo />} />
  </Route>
))

export default router
