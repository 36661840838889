enum Account_Class {
  ASSET = 'ASSET',
  EQUITY = 'EQUITY',
  EXPENSE = 'EXPENSE',
  LIABILITY = 'LIABILITY',
  REVENUE = 'REVENUE',
  OTHER = 'OTHER'
}

enum Account_Type {
  BANK = 'BANK',
  CURRENT = 'CURRENT',
  CURRLIAB = 'CURRLIAB',
  DEPRECIATN = 'DEPRECIATN',
  DIRECTCOSTS = 'DIRECTCOSTS',
  EQUITY = 'EQUITY',
  EXPENSE = 'EXPENSE',
  FIXED = 'FIXED',
  INVENTORY = 'INVENTORY',
  LIABILITY = 'LIABILITY',
  NONCURRENT = 'NONCURRENT',
  OTHERINCOME = 'OTHERINCOME',
  OVERHEADS = 'OVERHEADS',
  PREPAYMENT = 'PREPAYMENT',
  REVENUE = 'REVENUE',
  SALES = 'SALES',
  TERMLIAB = 'TERMLIAB',
  PAYGLIABILITY = 'PAYGLIABILITY',
  SUPERANNUATIONEXPENSE = 'SUPERANNUATIONEXPENSE',
  SUPERANNUATIONLIABILITY = 'SUPERANNUATIONLIABILITY',
  WAGESEXPENSE = 'WAGESEXPENSE',
}

enum Hidden_System_Account {
  DEBTORS = 'DEBTORS',
  CREDITORS = 'CREDITORS',
  BANKCURRENCYGAIN = 'BANKCURRENCYGAIN',
  TRACKINGTRANSFERS = 'TRACKINGTRANSFERS',
  UNPAIDEXPCLM = 'UNPAIDEXPCLM',
  UNREALISEDCURRENCYGAIN = 'UNREALISEDCURRENCYGAIN'
}

enum JE_Visible_Invoices {
  REG_ACT_EMP = 'All inclusive fees',
  OTHER = 'Other Charge',
  GST = 'GST',
  HST = 'HST',
  PST = 'PST',
}

enum Hide_JE_ITEMS{
  FedTax = 'Federal Income Tax',
  ProvTax = 'Provincial Tax',
  EI = 'Employment Insurance Contribution',
  CPP = 'Canada Pension Plan Contribution'
}

enum JE_Item_Type{
  INVOICE_CHARGE = 'Invoice Charge'
}

enum OVERRIDE_TABS {
  employee = 'employee',
  department = 'department'
}


export interface IJournalEntry {
  code: string
  description: string
  enabled: boolean
  id: number
  item_type: string
  obj_id: string
  mappings?: IMapping[]
}

export enum WARNING_LAYOUT {
  ACCOUNT_MISSING_OR_REMOVED = 'ACCOUNT_MISSING_OR_REMOVED'
}

export enum ACCOUNT_STATUS {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  DELETED = 'DELETED'
}

export interface IGeneralLedgerAccount {
  account_class: Account_Class
  account_type: Account_Type
  system_account: string
  description: string
  gl_code: string
  acct_num: string
  id: number
  is_xero: boolean
  name: string
  status: ACCOUNT_STATUS
}

export interface IDepartment {
  dept_code: string;
  dept_name: string;
  id: number;
  obj_id: string;
}

export interface IEmployee {
  id: number;
  emp_code: string;
  first_name: string;
  last_name: string;
  home_department_id: number;
  obj_id: string;
  status: number
}

export interface IMapping {
  id: number,
  journal_entry_item_id: number,
  department_id: number | null,
  employee_id: number | null,
  home_department_id: null,
  credit_account_id: number,
  // employee: {},
  // department: {},
  // home_department: {},
  journal_entry_item: {
    id: number,
    code: string
    description: string
    item_type: string
    enabled: boolean
    obj_id: string
  },
  credit_account: IGeneralLedgerAccount,
  allocations: IAllocation[],

  // filled on frontend
  hasInactiveAccount?: boolean
}

export interface IAllocation {
  id: number,
  percentage: number,
  debit_account_id: number,
  mapping_id: number
}

export interface IDefaultDebitCredit {
  credit?: number;
  debit?: number;
}

interface IAllocationForUpdatingMapping {
  id: number,
  percentage: number,
  debitAccountId: number
}
export interface IUpdateMapping {
  allocations: IAllocationForUpdatingMapping[]
  creditAccountId: number
  id: number
  journalEntryItemId: number
  departmentId?: number
  employeeId?: number
}

export { Account_Type, Account_Class, Hidden_System_Account, JE_Visible_Invoices, JE_Item_Type, Hide_JE_ITEMS, OVERRIDE_TABS };
