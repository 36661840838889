import { RclRow, RclTitle, RclIcon } from '@risepeopleinc/rcl-react'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../hooks/reduxHooks'
import { selectCurrentJournalEntry, selectSaveMappingLoading } from '../../../../reducers/journalEntrySlice'
import savedCheckmark from '../../../../assets/images/saved-checkmark-gray.svg'
import './JournalEntryFormHeader.scss'

export const JournalEntryFormHeader = () => {
  const selectedEntry = useAppSelector(selectCurrentJournalEntry)
  const saveMappingLoading = useAppSelector(selectSaveMappingLoading)
  const [showText, setShowText] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [showSpinnerFor1Sec, setShowSpinnerFor1Sec] = useState(false)


  useEffect(() => {
    if (saveMappingLoading && !isSaving) {
      setIsSaving(true)
      if (!showSpinnerFor1Sec) {
        setShowSpinnerFor1Sec(true)
        setTimeout(() => setShowSpinnerFor1Sec(false), 1000)
      }
    }
    
    if (!saveMappingLoading && isSaving && !showSpinnerFor1Sec) {
      setIsSaving(false)
      setShowText(true)
      setTimeout(() => setShowText(false), 2000)
    }
  }, [saveMappingLoading, isSaving, showSpinnerFor1Sec])

  return (
    <RclRow align="middle" justify="space-between" wrap={false}>
      <RclTitle level={2} className='select-debit-credit-title'>{selectedEntry ? selectedEntry?.description : ''}</RclTitle>
      <RclRow>
        {(saveMappingLoading || showSpinnerFor1Sec)
          && <RclIcon iconName="Spin" style={{
            color: '#555DF2',
            width: '1.5rem',
            height: '1.5rem'
          }} />}
        {
          showText &&
          <div className={`saved-wrapper`}>
            <img src={savedCheckmark} alt={"saved-checkmark"} />
            <p>Saved!</p>
          </div>
        }
      </RclRow>
    </RclRow>
  )
}
