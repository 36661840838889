import { RclCol, RclText, RclTitle, RclSelect, RclOption, RclFormItem, RclRow, RclButton, RclIcon } from "@risepeopleinc/rcl-react"
import { useEffect, useCallback, useMemo, useState } from "react"
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from "../../../../hooks/reduxHooks";
import { selectPrimaryBankAccount, setOrgs, setPrimaryBankAccount, selectCurrentIntegrationData } from "../../../../reducers/orgSlice";
import {
  setGeneralLedgerAccounts,
  selectGeneralLedgerAccountsLoading,
  setGeneralLedgerAccountsLoading,
  selectGeneralLedgerAccounts,
  selectGeneralLedgerAccountsGroupedByClass,
  setJournalEntries,
} from "../../../../reducers/journalEntrySlice";
import { selectCurrentOrg, setCurrentOrg } from "../../../../reducers/orgSlice";
import { IGeneralLedgerAccount } from "../../../../interfaces/IJournalEntry";
import useJeApi from "../../../../hooks/useJeApi";
import './JournalEntrySettings.scss';
import useErrorHandling from "../../../../hooks/useErrorHandling";
import DisconnectModal from "../DisconnectXeroModal/DisconnectModal";
import { IOrganization } from "../../../../interfaces/IOrganization";
import KBArticleLink from "../KBArticleLink/KBArticleLink";
import Box from "ui-box";
import { renderGLAccount } from "../../../../helpers";

const JournalEntrySettings = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const currentOrg = useAppSelector(selectCurrentOrg)
  const currentIntegration = useAppSelector(selectCurrentIntegrationData);
  const generalLedgerAccountsLoading = useAppSelector(selectGeneralLedgerAccountsLoading)
  const filteredAccounts = useAppSelector(selectGeneralLedgerAccountsGroupedByClass({ filterBank: true, filterSystemAccount: true, sorted: true }));
  const generalLedgerAccounts = useAppSelector(selectGeneralLedgerAccounts)
  const primaryBankAccount = useAppSelector(selectPrimaryBankAccount)
  const { get, patch, remove } = useJeApi()
  const { handleXeroError } = useErrorHandling()
  const [isDisconnecting, setIsDisconnecting] = useState<boolean>(false)

  const spreadFilteredAccounts: IGeneralLedgerAccount[] = useMemo(() => {
    const spreadFilterAccounts: IGeneralLedgerAccount[] = []
    for (const key in filteredAccounts) {
      if (filteredAccounts[key].length) spreadFilterAccounts.push(...filteredAccounts[key])
    }
    return spreadFilterAccounts
  }, [filteredAccounts])

  const getGeneralLedgers = useCallback(async () => {
    try {
      dispatch(setGeneralLedgerAccountsLoading(true))
      const fetchAccounts: IGeneralLedgerAccount[] = await get(`/general-ledger-accounts?organization_id=${currentOrg?.id}`)
      dispatch(setGeneralLedgerAccounts(fetchAccounts))
      dispatch(setGeneralLedgerAccountsLoading(false))
    } catch (err) {
      handleXeroError(err)
    }

  }, [currentOrg?.id, get, dispatch, handleXeroError])

  useEffect(() => {
    getGeneralLedgers();
  }, [getGeneralLedgers])

  useEffect(() => {
    if (!generalLedgerAccountsLoading && !primaryBankAccount && spreadFilteredAccounts.length) dispatch(setPrimaryBankAccount(spreadFilteredAccounts[0].id))
  }, [generalLedgerAccountsLoading, spreadFilteredAccounts, primaryBankAccount, dispatch])

  const saveXeroSetting = useCallback(async () => {
    if (!currentOrg) return;
    await patch(`/organization?organization_id=${currentOrg?.id}`, { default_credit_account_id: primaryBankAccount })
    const data: IOrganization = ({ ...currentOrg, default_credit_account_id: primaryBankAccount })
    dispatch(setCurrentOrg(data))
    navigate("/dashboard")
  }, [navigate, currentOrg, primaryBankAccount, patch, dispatch])

  const disconnectIntegration = useCallback(async () => {
    if (!currentOrg) return;
    const disconnectEndpoint = `${currentIntegration?.value}_disconnect`
    try {
      setIsDisconnecting(true)
      await remove(`${disconnectEndpoint}?organization_id=${currentOrg.id}`)
      dispatch(setCurrentOrg({ ...currentOrg, xero_org_connections: [], qbo_org_connections: [] }))
      const fetchOrgs = await get<IOrganization[]>('/organizations')
      dispatch(setOrgs(fetchOrgs))
      setPrimaryBankAccount(undefined)
      dispatch(setGeneralLedgerAccounts([]))
      dispatch(setJournalEntries([]))
      navigate('/integration-connect')
      setIsDisconnecting(false)
    } catch (error) {
      setIsDisconnecting(false)
      handleXeroError(error)
    }
  }, [currentOrg, currentIntegration, remove, dispatch, get, navigate, handleXeroError])

  const getNameAndCodeOfPrimaryBankAccount = useCallback(() => {
    const res = generalLedgerAccounts.find(x => x.id === primaryBankAccount)
    if (res)
      return renderGLAccount(res, currentIntegration!.value)
    if (spreadFilteredAccounts.length > 0)
      return renderGLAccount(spreadFilteredAccounts[0], currentIntegration!.value)
  }, [generalLedgerAccounts, spreadFilteredAccounts, currentIntegration, primaryBankAccount])

  useEffect(() => {
    if (!currentIntegration) navigate("/integration-connect")
  }, [currentIntegration, navigate])

  return (
    <div className="connect-bank-container">
      <div className="connect-bank-wrapper">
        <RclTitle>Journal entry settings</RclTitle>
        <RclRow className="xero-wrapper">
          <div className="connected-with-wrapper">
            <RclIcon className="logo" iconName={`${currentIntegration?.value}Logo`} component={currentIntegration?.icon}></RclIcon>
            <RclCol>
              <RclText className="connect-with-xero-text">Connected With</RclText>
              <br />
              <RclText className="tenant-name-text">{currentIntegration?.name}</RclText>
            </RclCol>
          </div>
          <DisconnectModal disconnectIntegration={disconnectIntegration} isDisconnecting={isDisconnecting} />
        </RclRow>
        <Box marginTop={16}>
          <RclTitle level={2}>Payroll clearing account setup</RclTitle>
          <RclText className="je-settings-tips">
          We'll use the selected account to pre-fill the credit mappings for all your payroll instructions and service charges. You can switch to a different account in the Settings page.
            <KBArticleLink link='https://support.risepeople.com/portal/en/kb/articles/journal-entry-solution-faqs#11_I_have_to_select_my_payroll_clearing_account_but_I_dont_see_my_bank_account_listed_among_the_options_What_should_I_do' />
          </RclText>
          <br />
          <RclText className="je-settings-tips">
            If you do not have a clearing account, please create one in {currentIntegration?.name}, as they will not allow the use of bank accounts for journal entries.
          </RclText>
        </Box>
        <Box paddingLeft={24}>
        <RclFormItem>
          {!generalLedgerAccountsLoading ?
            <RclSelect
              defaultValue={getNameAndCodeOfPrimaryBankAccount()}
              onSelect={value => dispatch(setPrimaryBankAccount(JSON.parse(value).id))}
            >
              {spreadFilteredAccounts.map((gl: IGeneralLedgerAccount) =>
                <RclOption key={gl.id} value={JSON.stringify(gl)}>
                  {renderGLAccount(gl, currentIntegration!.value)}
                </RclOption>)}
            </RclSelect>
            :
            <div>LOADING</div>
          }
        </RclFormItem>
        </Box>
        <RclButton className="save-je-settings-button"
          size="middle"
          disabled={generalLedgerAccountsLoading}
          type="primary"
          onClick={saveXeroSetting}
        >
          <p>Save changes</p>
        </RclButton>
      </div>
    </div>
  );
}

export default JournalEntrySettings
