import { useCallback } from "react"
import { toast, ToastOptions } from "react-toastify"
import savedCheckmark from '../assets/images/saved-checkmark.svg'

const useCustomToast = () => {
  const showToastSuccess = useCallback((message: string, additionalOptions?: ToastOptions) => {
    toast.success(message, {
      icon: () =>  <img src={savedCheckmark} alt='saved-checkmark'/>,
      autoClose: 8000,
      style: {
        width: '450px',
        backgroundColor: '#40474D',
        color: '#fff',
        boxShadow: '0px 16px 36px -12px rgba(9, 36, 124, 0.12), 0px 3px 3px -2px rgba(7, 29, 99, 0.1), 0px 12px 16px -8px rgba(9, 36, 124, 0.12)'
      },
      ...additionalOptions
    })
  }, [])

  return { showToastSuccess }
}

export default useCustomToast
