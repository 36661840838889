import { RclButton, RclModal, RclSpace, RclText, RclTitle } from "@risepeopleinc/rcl-react"
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import XeroDisconnected from "../../assets/images/xero-disconnected.svg";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import useGlobalModals from "../../hooks/useGlobalModals";
import { selectCurrentIntegrationData, selectCurrentOrg, setBrokenOrgs } from "../../reducers/orgSlice";
import './XeroReconnectModal.scss'

const XeroReconnectModal = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const currentOrg = useAppSelector(selectCurrentOrg)
  const currentIntegration = useAppSelector(selectCurrentIntegrationData);
  const { xeroReconnectOpen, setXeroReconnectOpenAction } = useGlobalModals()

  const onReconnect = useCallback(async () => {
    if (!currentOrg) return;
    dispatch(setBrokenOrgs([currentOrg.id]))
    setXeroReconnectOpenAction(false)
    navigate('/integration-connect', { replace: false })
  }, [navigate, currentOrg, dispatch, setXeroReconnectOpenAction])

  return (
    <RclModal
      wrapClassName="xero-reconnect-modal-wrapper"
      visible={xeroReconnectOpen}
      closable={false}
      maskClosable={false}
      footer={
        <div className="xero-reconnect-modal-button-wrapper">
          <RclButton key="back" onClick={() => setXeroReconnectOpenAction(false)} disabled={false}>
            Cancel
          </RclButton>
          <RclButton
            key="link"
            type="primary"
            loading={false}
            disabled={false}
            onClick={onReconnect}>
            Reconnect to {currentIntegration?.name}
          </RclButton>
        </div>
      }>
      <RclSpace direction='vertical' className="xero-reconnect-modal-body-wrapper">
        <img src={XeroDisconnected} alt='xero-disconnected' />
        <RclTitle level={2}>Something's missing</RclTitle>
        <RclText>It appears this integration was disconnected from {currentIntegration?.name}. We can no longer update your chart of accounts or send reports to {currentIntegration?.name} without a connection.</RclText>
      </RclSpace>
    </RclModal>
  )
}

export default XeroReconnectModal
